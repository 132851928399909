import { axiosRequest } from "./Axios";
import useAxiosPrivate from "../hook/useAxiosPrivate";

export async function GetRequest(url: string, params?: string) {
  if (params) {
    return await axiosRequest
      .get(`/${url}?${params}`)
      .then((response) => response);
  }
  return await axiosRequest.get(`/${url}`).then((response) => response);
}

export async function PostRequest(url: string, payload: any) {
  return await axiosRequest
    .post(`/${url}`, payload)
    .then((response) => response);
}

export async function PutRequest(url: string, payload: any) {
  return await axiosRequest
    .put(`/${url}`, payload)
    .then((response) => response);
}

export async function PatchRequest(url: string, payload: any) {
  return await axiosRequest
    .patch(`/${url}`, payload)
    .then((response) => response);
}

export async function DeleteRequest(url: string) {
  return await axiosRequest.delete(`/${url}`).then((response) => response);
}
