import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PatchRequest, PostRequest, GetRequest } from "../../../api/Request";
import {
  AxiosServerResponse,
  CategoryType,
  SubCategory,
} from "./category.types";
import { toast } from "react-toastify";
import CategoryForm from "./categoryFrom/CategoryForm";

type Props = {};

function Category({}: Props) {
  const [categoryList, setGategoryList] = useState<AxiosServerResponse>({
    data: [],
    statusCode: 0,
    success: false,
    limit: 50,
    page: 1,
  });
  const [category, setCategory] = useState<CategoryType>({
    name: "",
    type: "",
    description: "",
    icon: "",
    subCategoryList: [],
  });
  const [formModal, setFormModal] = useState<boolean>(false);
  const [type, setType] = useState<string>("Product");
  const [subCategory, setSubCategory] = useState<SubCategory>({
    _id: "",
    name: "",
    // type: "",
    description: "",
    icon: "",
  });
  function handleSubCategoryChange(
    params: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>,
  ) {
    if (params.target.name === "icon") {
      let img = params.target.files[0];
      const formData = new FormData();
      formData.append("file", img);
      PostRequest(`v1/file?type=icon`, formData)
        .then((res) => {
          setSubCategory((pre) => ({ ...pre, icon: res.data.url }));
        })
        .catch((e) => {
          //  console.log(e)
        });
    } else
      setSubCategory((pre) => ({
        ...pre,
        [params.target.name]: params.target.value,
      }));
  }
  function handleChange(
    params: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>,
  ) {
    if (params.target.name === "icon") {
      let img = params.target.files[0];
      const formData = new FormData();
      formData.append("file", img);
      PostRequest(`v1/file?type=icon`, formData)
        .then((res) => {
          setCategory((pre) => ({ ...pre, icon: res.data.url }));
        })
        .catch((e) => {
          //  console.log(e)
        });
    } else
      setCategory((pre) => ({
        ...pre,
        [params.target.name]: params.target.value,
      }));

    // console.log(category);
  }
  function handleSearchInList(params: React.ChangeEvent<HTMLInputElement>) {}
  async function handleUpdate() {
    try {
      // console.log(category);
      const response: any = await PatchRequest(
        "v1/category/" + category._id,
        category,
      );
      setCategory((pre: any) => response);
      toast.success(" Category Added Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      // console.log(error);
    }
  }
  function handleRemove(params: string) {
    let newarray = category.subCategoryList?.filter(
      (item) => item.name !== params,
    );
    // console.log(newarray);
    setCategory((pre: any) => ({ ...pre, subCategoryList: newarray }));
    // console.log(category);
  }
  async function handleSubmit() {
    try {
      // console.log(category);
      const response: any = await PostRequest("v1/category", category);
      if (response.statusCode === 200) {
        fetchCategoryList();
      }
      toast.success(" Category Added Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      // console.log(error);
    }
  }
  function fetchCategoryList() {
    GetRequest(`v1/category?page=1&limit=50${type ? "&type=" + type : ""}`)
      .then((res: any) => {
        // console.log(res);
        setGategoryList((pre) => res);
      })
      .catch((e) => {
        //  console.log(e)
      });
  }
  useEffect(() => {
    fetchCategoryList();
  }, [type]);

  return (
    <section className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        {/* Start coding here */}
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2 flex flex-row justify-between max-h-10">
              <div className="relative w-3/5 flex flex-row">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  // onChange={(event)=>setType(event.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Search"
                />
              </div>

              <select
                id="category"
                name="type"
                value={type}
                onChange={(event) => setType(event.target.value)}
                className="bg-gray-50 max-w-[200px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option defaultChecked value="Product">
                  Product
                </option>
                <option value="Service">Service</option>
                {/* <option value="Store">Store</option>
                <option value="User">User</option> */}
              </select>

              <button
                onClick={() => setFormModal(true)}
                type="button"
                className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 bg-blue-900"
              >
                <svg
                  className="h-3.5 w-3.5 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  />
                </svg>
                Add New Category
              </button>
            </div>
            <div className="w-96 flex flex-row justify-end gap-x-2.5 overflow-scroll">
              {formModal && (
                <CategoryForm
                  showModal={formModal}
                  data={category}
                  updateData={() => {}}
                  closeModal={() => setFormModal(false)}
                />
              )}
              {/* <div
                id="defaultModal"
                // tabIndex={-1}
                className={`${
                  formModal ? "" : "hidden "
                } overflow-y-auto overflow-x-hidden  fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full flex-wraps`}
              >
                <div className="relative p-4 w-full h-auto md:h-auto">
                  <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                      <h3 className="text-2xl font-semibold text-blue-900 dark:text-white">
                        Add New Category
                      </h3>
                      <button
                        onClick={() => setFormModal(false)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-toggle="defaultModal"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>

                    <div className="flex flex-wrap  mb-4 w-full justify-between  ">
                      <div className="basis-[45%] mb-10">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Category Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={category.name}
                          onChange={handleChange}
                          id="name"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Type product name"
                          required={true}
                        />
                      </div>
                      <div className="basis-[45%] mb-14">
                        <label
                          htmlFor="category"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Category Type
                        </label>
                        <select
                          id="category"
                          name="type"
                          value={category.type}
                          onChange={handleChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        >
                          <option selected={true}>Select category</option>
                          <option value="Product">Product</option>
                          <option value="Service">Service</option>
                          <option value="Store">Store</option>
                          <option value="User">User</option>
                        </select>
                      </div>
                      <div className="flex flex-wrap  mb-4 w-full justify-between rounded-md border-2 p-5  relative">
                        <h1 className="absolute top-[-15px]">
                          {" "}
                          Add New Sub-Category{" "}
                        </h1>
                        <div className="basis-[45%] mb-10">
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Sub-Category Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={subCategory.name}
                            onChange={handleSubCategoryChange}
                            id="name"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Type product name"
                            required={true}
                          />
                        </div>
                        <div className="basis-[45%]">
                          <label
                            htmlFor="description"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Description
                          </label>
                          <textarea
                            id="description"
                            rows={1}
                            value={subCategory.description}
                            name="description"
                            onChange={handleSubCategoryChange}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Write product description here"
                            defaultValue={""}
                          />
                        </div>
                        <div className="flex items-center justify-center basis-[45%] ">
                          {subCategory?.icon ? (
                            <>
                              <img
                                src={subCategory?.icon}
                                className="w-10 h-10"
                              />
                              <p
                                className="mb-2 text-sm text-red-500 dark:text-gray-400 hover:cursor-pointer"
                                onClick={() =>
                                  setSubCategory((pre) => ({
                                    ...pre,
                                    icon: "",
                                  }))
                                }
                              >
                                update Logo
                              </p>
                            </>
                          ) : (
                            <label
                              htmlFor="dropzone-file-sub-Category"
                              className="flex flex-col items-center justify-center w-full h-12 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                              <div className="flex items-center justify-between ">
                                <svg
                                  className="w-8 h-8 mb-2 text-gray-500 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 16"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                  />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                  <span className="font-semibold">
                                    Click to upload Icon
                                  </span>
                                </p>
                              </div>
                              <input
                                id="dropzone-file-sub-Category"
                                type="file"
                                name="icon"
                                onChange={handleSubCategoryChange}
                                className="hidden"
                              />
                            </label>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            if (
                              subCategory.name &&
                              subCategory.icon &&
                              category.type
                            ) {
                              setCategory((pre: any) => {
                                if (pre?.subCategoryList?.length) {
                                  return {
                                    ...pre,
                                    subCategoryList: [
                                      ...pre.subCategoryList,
                                      subCategory,
                                    ],
                                  };
                                } else {
                                  return {
                                    ...pre,
                                    subCategoryList: [subCategory],
                                  };
                                }
                              });
                              setSubCategory((pre) => ({
                                _id: "",
                                name: "",
                                type: "",
                                description: "",
                                icon: "",
                              }));
                            } else {
                              toast.error(
                                "name & icon and type should not be empty",
                                {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                },
                              );
                            }
                          }}
                          type="button"
                          className="text-white inline-flex  items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 bg-blue-700 self-center"
                        >
                          Add new Sub-Category
                        </button>
                      </div>
                      <div
                        className={`${
                          category.subCategoryList?.length ? "flex" : "hidden"
                        } flex-wrap max-h-52   overflow-y-scroll  mb-4 w-full justify-between rounded-md border-2 p-5  relative`}
                      >
                        <h1 className="absolute top-[-15px]">
                          {" "}
                          Sub-Category List
                        </h1>
                        <table className="w-full h-full text-sm text-left text-gray-500 dark:text-gray-400 min-h-full">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" className="px-4 py-3">
                                Category name
                              </th>
                              <th scope="col" className="px-4 py-3">
                                Type
                              </th>
                              <th scope="col" className="px-4 py-3">
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {category &&
                              category?.subCategoryList?.map((item) => (
                                <tr
                                  className="border-b dark:border-gray-700"
                                  key={item.name}
                                >
                                  <th
                                    scope="row"
                                    className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    {item.name}
                                  </th>
                                  <img src={item.icon} className="w-10 h-10" />
                                  <td className="px-4 py-3">
                                    {item.description}
                                  </td>
                                  <td className="px-4 py-3">
                                    <p
                                      className="mb-2 text-sm text-red-500 dark:text-gray-400 hover:cursor-pointer"
                                      onClick={() => handleRemove(item.name)}
                                    >
                                      Remove
                                    </p>
                                    <p
                                      className="mb-2 text-sm text-red-500 dark:text-gray-400 hover:cursor-pointer"
                                      onClick={() => {
                                        setSubCategory((pre) => item);
                                        handleRemove(item.name);
                                      }}
                                    >
                                      Edit
                                    </p>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="basis-[45%]">
                        <label
                          htmlFor="description"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Description
                        </label>
                        <textarea
                          id="description"
                          rows={2}
                          name="description"
                          value={category.description}
                          onChange={handleChange}
                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Write product description here"
                          defaultValue={""}
                        />
                      </div>
                      <div className="flex items-center justify-center basis-[45%] mt-8">
                        {category.icon ? (
                          <>
                            <img src={category.icon} className="w-10 h-10" />
                            <p
                              className="mb-2 text-sm text-red-500 dark:text-gray-400 hover:cursor-pointer"
                              onClick={() =>
                                setCategory((pre) => ({ ...pre, icon: "" }))
                              }
                            >
                              update Logo
                            </p>
                          </>
                        ) : (
                          <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-16 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                          >
                            <div className="flex items-center justify-between ">
                              <svg
                                className="w-8 h-8 mb-2 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload Icon
                                </span>
                              </p>
                            </div>
                            <input
                              id="dropzone-file"
                              type="file"
                              name="icon"
                              onChange={handleChange}
                              className="hidden"
                            />
                          </label>
                        )}
                      </div>
                    </div>
                    {category._id ? (
                      <button
                        onClick={handleUpdate}
                        type="button"
                        className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 bg-blue-700 self-center"
                      >
                        Update Category
                      </button>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        type="button"
                        className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 bg-blue-700 self-center"
                      >
                        Add new Category
                      </button>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="overflow-x-auto m-5">
            <table className="w-full h-full text-sm text-left text-gray-500 dark:text-gray-400 min-h-full">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Category name
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Number of Sub-Category
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Description
                  </th>
                  <th scope="col" className="px-4 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {categoryList &&
                  categoryList.data.map((item) => (
                    <tr
                      className="border-b dark:border-gray-700"
                      key={item._id}
                    >
                      <th
                        scope="row"
                        className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.name}
                      </th>
                      <td className="px-4 py-3">
                        {item?.subCategoryList?.length}
                      </td>
                      <td className="px-4 py-3">{item.status}</td>
                      <td className="px-4 py-3">{item.description}</td>
                      <td className="px-4 py-3">{item.type}</td>
                      <td className="px-4 py-3 flex items-center justify-end relative">
                        <ShowAction
                          item={item}
                          setCategory={setCategory}
                          setFormModal={setFormModal}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="flex flex-col justify-end my-10">
              <p className="inline-flex justify-end text-gray-500">
                total Category : {categoryList.data.length}
              </p>
              <div className="flex justify-end  ">
                <p
                  className="flex items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white hover:cursor-pointer"
                  onClick={async () => {
                    if (categoryList.page === 1) {
                      toast.error("Opps..!You are on First page ", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    } else
                      GetRequest(
                        `v1/category?page=${categoryList.page - 1}&limit=50${
                          type ? "&type=" + type : ""
                        }`,
                      )
                        .then((res: any) => {
                          setGategoryList((pre) => res);
                        })
                        .catch((e) => {
                          //  console.log(e)
                        })
                        .finally(() => {});
                  }}
                >
                  <svg
                    className="w-3.5 h-3.5 me-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 5H1m0 0 4 4M1 5l4-4"
                    />
                  </svg>
                  Previous
                </p>
                <p className="flex items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  {categoryList.page}
                </p>
                <p
                  className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white hover:cursor-pointer"
                  onClick={async () => {
                    if (categoryList.data.length < categoryList.page * 50) {
                      toast.error("Opps..!No more page to fetch", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    } else
                      GetRequest(
                        `v1/category?page=${categoryList.page + 1}&limit=50${
                          type ? "&type=" + type : ""
                        }`,
                      )
                        .then((res: any) => {
                          setGategoryList((pre) => res);
                        })
                        .catch((e) => console.log(e))
                        .finally(() => console.log("conplited"));
                  }}
                >
                  Next
                  <svg
                    className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category;
type ShowActionProps = {
  item: CategoryType;
  setCategory: Dispatch<SetStateAction<CategoryType>>;
  setFormModal: Dispatch<SetStateAction<boolean>>;
};
function ShowAction({ item, setCategory, setFormModal }: ShowActionProps) {
  const [showModal, setModal] = useState<boolean>(false);
  return (
    <>
      {" "}
      <button
        id="apple-imac-27-dropdown-button"
        onClick={() => setModal(!showModal)}
        className="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
        type="button"
      >
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
        </svg>
      </button>
      <div
        id="apple-imac-27-dropdown"
        className={`${
          showModal ? "" : "hidden"
        }  absolute top-10 z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600`}
      >
        <ul
          className="py-1 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="apple-imac-27-dropdown-button"
        >
          <li>
            <Link
              to="#"
              onClick={() => {
                setCategory(item);
                setFormModal(true);
                setModal(false);
              }}
              className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              Edit
            </Link>
          </li>
        </ul>
        <div className="py-1">
          <Link
            to="#"
            className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
          >
            Delete not working
          </Link>
        </div>
      </div>
    </>
  );
}
