import React, { useEffect, useRef, useState, useMemo } from "react";
import "react-phone-number-input/style.css";
import { AiFillShop } from "react-icons/ai";
import "./login.scss";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { isLoginSelector, loginAsynkThunk } from "../../../features/auth/Slice";
import { PostRequest } from "../../../api/Request";
const CssTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "0.75rem 1.25rem",
  },
  "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
    lineHeight: "1rem",
    fontSize: "0.90rem",
    color: "#9FA6B3",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "0.35rem",
      borderColor: "#E5E7EB",
    },
  },
});

export default function Login() {
  const [phoneNumberOTP, setPhoneNumberOTP] = useState<string[]>(
    new Array(6).fill(""),
  );
  const [startPhoneOTPTimer, setStartPhoneOTPTimer] = useState(false);
  const [resendPhoneOTPTimer, setResendPhoneOTPTimer] = useState(30);

  const [phoneNumberObj, setPhoneNumberObj] = useState({
    phoneNumber: "",
    countryCode: "+91",
    error: "",
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [errorOfOTP, setErrorOfOTP] = useState("");
  const [sentOrResendOTPMsg, setSentOrResendOTPMsg] = useState(
    "OTP has been sent to your phone number",
  );
  const [otpId, setOtpId] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const phoneNumberOTPHandleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newPhoneNumberOTP: string[] = [...phoneNumberOTP];

    if (key === "Backspace") {
      if (newPhoneNumberOTP[index] === "") {
        setActiveOTPIndex(index - 1);
        newPhoneNumberOTP[index - 1] = "";
      } else {
        newPhoneNumberOTP[index] = "";
        setPhoneNumberOTP(newPhoneNumberOTP);
        setActiveOTPIndex(index);
      }
    } else if (key.match("[0-9]")) {
      newPhoneNumberOTP[index] = key;
      setActiveOTPIndex(index + 1);
      setPhoneNumberOTP(newPhoneNumberOTP);
    }
  };
  const isLogedIn = useSelector(isLoginSelector);
  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOTPIndex]);

  const sendOTP = async (e: any) => {
    e.preventDefault();
    if (phoneNumberObj.phoneNumber.length == 10) {
      await PostRequest("v1/auth/send-otp", {
        countryCode: phoneNumberObj.countryCode,
        nationalNumber: phoneNumberObj.phoneNumber,
      })
        .then((res: any) => {
          if (res?.statusCode == 200) {
            setCurrentStep((pre) => 2);
            setOtpId(res.data.otpId);
          }
        })
        .catch((err) =>
          setPhoneNumberObj({
            ...phoneNumberObj,
            error: err.response.data.message,
          }),
        );
    } else {
      setPhoneNumberObj({
        ...phoneNumberObj,
        error: "Please enter a valid Phone Number",
      });
    }
  };

  const handleSubmitOTP = async (event: any) => {
    dispatch(
      loginAsynkThunk({
        countryCode: phoneNumberObj.countryCode,
        nationalNumber: phoneNumberObj.phoneNumber,
        otp: phoneNumberOTP.join(""),
        otpId,
      }),
    );
    localStorage.setItem("userLogIn", "true");
  };

  const calculateResendPhoneOTPTimer = useMemo(() => {
    if (resendPhoneOTPTimer <= 0) {
      setStartPhoneOTPTimer(false);
      return 0;
    }
    return resendPhoneOTPTimer;
  }, [resendPhoneOTPTimer]);
  useEffect(() => {
    let intervalId: any;
    if (startPhoneOTPTimer) {
      intervalId = setInterval(() => {
        setResendPhoneOTPTimer(
          (resendPhoneOTPTimer) => resendPhoneOTPTimer - 1,
        );
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startPhoneOTPTimer]);

  const handleStartPhoneOTPTimer = () => {
    setStartPhoneOTPTimer(true);
  };

  return (
    <div className="parent_cnt">
      <div className="cnt">
        {currentStep == 1 ? (
          <div className="cnt">
            <div className="user_icon_cnt">
              <AiFillShop className="user_icon" />
            </div>
            <h1 className="heading">Welcome!</h1>
            <p className="greeting">
              Let's me make some ease for Shopkeepers. <br />
              Please enter {currentStep == 1 ? "your Phone Number" : "OTP"} to
              continue.
            </p>

            <form>
              <div
                className="phone_number"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <CssTextField
                  sx={{
                    width: "95px",
                    margin: "1.2rem 0",
                    "& .": {
                      color: "#013FB9",
                    },
                  }}
                  value="🇮🇳 +91"
                  type="text"
                  id="outlined-basic"
                  aria-readonly={true}
                />
                <CssTextField
                  sx={{
                    width: "340px",
                    marginLeft: "1.25rem",
                    margin: "1.2rem 0",
                    "& .": {
                      color: "#013FB9",
                    },
                  }}
                  value={phoneNumberObj.phoneNumber}
                  placeholder="Enter your Phone Number"
                  onChange={(event) => {
                    let { value } = event.target;
                    if (value.length == 10) {
                      setPhoneNumberObj({
                        ...phoneNumberObj,
                        phoneNumber: value,
                        error: "",
                      });
                    } else {
                      setPhoneNumberObj({
                        ...phoneNumberObj,
                        phoneNumber: value,
                        error: "Please enter a valid Phone Number",
                      });
                    }
                  }}
                  type="number"
                  id="outlined-basic"
                  name="email"
                  label="Phone Number"
                  error={phoneNumberObj.error ? true : false}
                  helperText={phoneNumberObj.error ? phoneNumberObj.error : ""}
                />
              </div>
              <button type="submit" onClick={sendOTP} className="send_otp_btn">
                Send OTP
              </button>
            </form>
          </div>
        ) : (
          <Otp
            setSentOrResendOTPMsg={setSentOrResendOTPMsg}
            sentOrResendOTPMsg={sentOrResendOTPMsg}
            startPhoneOTPTimer={startPhoneOTPTimer}
            handleStartPhoneOTPTimer={handleStartPhoneOTPTimer}
            setResendPhoneOTPTimer={setResendPhoneOTPTimer}
            resendPhoneOTPTimer={resendPhoneOTPTimer}
            calculateResendPhoneOTPTimer={calculateResendPhoneOTPTimer}
            currentStep={currentStep}
            activeOTPIndex={activeOTPIndex}
            inputRef={inputRef}
            phoneNumberOTP={phoneNumberOTP}
            handleSubmitOTP={handleSubmitOTP}
            errorOfOTP={errorOfOTP}
            sendOTP={sendOTP}
            phoneNumberOTPHandleOnKeyDown={phoneNumberOTPHandleOnKeyDown}
          />
        )}
      </div>
    </div>
  );
}

function Otp(props: any) {
  let {
    sendOTP,
    handleStartPhoneOTPTimer,
    setResendPhoneOTPTimer,
    resendPhoneOTPTimer,
    calculateResendPhoneOTPTimer,
    currentStep,
    inputRef,
    phoneNumberOTP,
    activeOTPIndex,
    phoneNumberOTPHandleOnKeyDown,
    handleSubmitOTP,
    errorOfOTP,
    startPhoneOTPTimer,
    sentOrResendOTPMsg,
    setSentOrResendOTPMsg,
  } = props;

  useEffect(() => {
    handleStartPhoneOTPTimer();
  }, []);

  return (
    <div className="cnt">
      <div className="user_icon_cnt">
        <AiFillShop className="user_icon" />
      </div>
      <h1 className="heading">Welcome!</h1>
      <p className="greeting">
        Let's me make some ease for shopkeepers. <br />
        Please enter {currentStep == 1 ? "your Phone Number" : "OTP"} to
        continue.
      </p>
      <p
        className="greeting"
        style={{
          color: "green",
          marginTop: "0.75rem",
        }}
      >
        {sentOrResendOTPMsg}
      </p>
      <div className="otp">
        <div className="cnt_otp">
          {phoneNumberOTP.map((a: any, index: number) => {
            return (
              <input
                key={index}
                value={phoneNumberOTP[index]}
                onChange={(event) => {
                  return;
                }}
                type="number"
                maxLength={1}
                onFocus={(event) => {
                  event.target.classList.add("border-solid");
                  event.target.classList.add("border-[1.5px]");
                  event.target.classList.add("border-black");
                }}
                onBlur={(event) => {
                  event.target.classList.remove("border-solid");
                  event.target.classList.remove("border-[1.5px]");
                  event.target.classList.remove("border-black");
                }}
                ref={index === activeOTPIndex ? inputRef : null}
                onKeyDown={(event) =>
                  phoneNumberOTPHandleOnKeyDown(event, index)
                }
                className="otp_field input"
              />
            );
          })}
        </div>
        <div
          className="resend_otp"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ color: "red" }}>{errorOfOTP}</span>
          <div
            onClick={async () => {
              if (startPhoneOTPTimer != true) {
                await sendOTP();
                handleStartPhoneOTPTimer();
                setResendPhoneOTPTimer(3);
                setSentOrResendOTPMsg("OTP has been resend to your number");
              }
            }}
            style={{
              color: resendPhoneOTPTimer == 0 ? "blue" : "gray",
            }}
          >
            Resend OTP
            {resendPhoneOTPTimer == 0
              ? ""
              : " in " + calculateResendPhoneOTPTimer}
          </div>
        </div>

        <button
          type="submit"
          onClick={handleSubmitOTP}
          className="send_otp_btn"
        >
          Submit OTP
        </button>
      </div>
    </div>
  );
}
