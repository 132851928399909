import { useEffect, useState, useRef } from "react";
import { GetRequest } from "../../../api/Request";
import { FiSearch } from "react-icons/fi";
import "./styles/brand.scss";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../component/addButton/AddButton";
import { RxCross1 } from "react-icons/rx";
import BrandForm from "./brandForm/BrandForm";
import { IBrand } from "./brand.types";

export default function Brand(_props: any) {
  const [loading, setLoading] = useState(false);
  const [formModal, setFormModal] = useState<boolean>(false);
  const [brandDetail, setBrandDetail] = useState<IBrand>();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [brand, setBrand] = useState<any[]>([]);
  const brandListRef = useRef(null);
  const [hasMoreBrand, setHasMoreBrand] = useState<any>("");
  const [searchedValueForBrand, setSearchedValueForBrand] = useState<any>("");

  const toggleActionMenu = (index: number) => {
    let updatedBrand = [...brand];

    updatedBrand = updatedBrand.map((obj: any, i: any) => {
      if (i !== index) {
        return {
          ...obj,
          isActionMenuOpen: false,
        };
      } else {
        return {
          ...obj,
          isActionMenuOpen: true,
        };
      }
    });
    setBrand(updatedBrand);
  };

  const closeTheActionMenu = (index: number) => {
    let updatedBrand = [...brand];

    updatedBrand = updatedBrand.map((obj: any, i: any) => {
      return {
        ...obj,
        isActionMenuOpen: false,
      };
    });
    setBrand(updatedBrand);
  };

  useEffect(() => {
    getBrandOnLoad();
  }, []);

  const getBrandAccToSearchedValue = async (value: any) => {
    await GetRequest(`v1/brand?name=${value}&limit=${limit}&page=${pageNumber}`)
      .then(async (res) => {
        setBrand(res.data);
        setHasMoreBrand((prevValue: any) => res.data.limit === limit);
        setBrand((prevState) => [
          ...res.data.map((data: any) => {
            data.isActionMenuOpen = false;
            return data;
          }),
        ]);
      })
      .catch((err) => {});
  };

  const getBrandOnLoad = async () => {
    await GetRequest(`v1/brand?limit=${limit}&page=${pageNumber}`)
      .then(async (res) => {
        setBrand(res.data);
        setHasMoreBrand((prevValue: any) => res.data.limit === limit);
        setBrand((prevState) => [
          ...res.data.map((data: any) => {
            data.isActionMenuOpen = false;
            return data;
          }),
        ]);
      })
      .catch((err) => {});
  };
  const onIntersectionForBrandListRef = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreBrand) {
      setPageNumber((prevPageNumber: any) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (brandListRef.current) {
      const observer = new IntersectionObserver(onIntersectionForBrandListRef);
      observer.observe(brandListRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [brand.length > 0, hasMoreBrand]);

  const handleChangeOnSearchedValue = async (event: any) => {
    let { value } = event.target;
    setSearchedValueForBrand(value);
    setPageNumber(1);
    getBrandAccToSearchedValue(value);
  };

  return (
    <>
      <div className="parent_cnt_order_page">
        <div
          className="order_page_big_cnt"
          style={{
            position: "relative",
          }}
        >
          <div className="search-bar-component">
            <div className="search-bar">
              <input
                type="text"
                name="product-name"
                placeholder="Search Brand..."
                value={searchedValueForBrand}
                onChange={handleChangeOnSearchedValue}
              />
              <button className="search-icon">
                <FiSearch
                  onClick={() =>
                    getBrandAccToSearchedValue(searchedValueForBrand)
                  }
                />
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <table className="custom-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            {brand.length > 0 &&
              brand.map((data: any, index: number) => {
                return (
                  <tbody key={data._id}>
                    <tr>
                      <td>{data.name}</td>
                      <td>{data.description}</td>
                      <td>
                        <div
                          className={`action-wrapper ${
                            data.isActionMenuOpen ? "open" : ""
                          }`}
                        >
                          <div
                            className="three-dots"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => toggleActionMenu(index)}
                          >
                            <span> &#8226;</span>
                            <span> &#8226;</span>
                            <span> &#8226;</span>
                          </div>
                          {data.isActionMenuOpen && (
                            <div
                              className="action-menu"
                              onBlur={() => {
                                // console.log("working on blur");
                                closeTheActionMenu(index);
                              }}
                            >
                              <button onClick={() => closeTheActionMenu(index)}>
                                <RxCross1 />
                              </button>
                              <button
                                onClick={() => {
                                  setFormModal((pre) => true);
                                  setBrandDetail((pre) => data);
                                  // navigate(`/brand/${data._id}`);
                                }}
                              >
                                Edit Brand
                              </button>
                              <button
                                onClick={() => {
                                  navigate(`/brand/${data._id}/products`);
                                }}
                              >
                                Open Product
                              </button>
                              <button
                                onClick={() => {
                                  navigate(`/brand/${data._id}/create-product`);
                                }}
                              >
                                Add New Product
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            {brand.length > 0 && (
              <div ref={brandListRef} style={{ padding: "10px" }}></div>
            )}
          </table>
        </div>
        <div
          onClick={() => {
            setFormModal((pre) => true);
            setBrandDetail((pre) => undefined);
            // navigate(`create`);
          }}
        >
          <AddButton />
        </div>
      </div>
      {formModal && (
        <BrandForm
          showModal={formModal}
          data={brandDetail}
          updateStore={() => {}}
          closeModal={() => setFormModal(false)}
        />
      )}
    </>
  );
}
