import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  FormikErrors,
} from "formik";
import {
  AxiosServerResponse,
  SubCategory,
} from "../../category/category.types";
import { RxCross2 } from "react-icons/rx";
import { GetRequest, PostRequest } from "../../../../api/Request";
import {
  getUpdatedValues,
  removeEmptyElements,
  validationSchema,
  initialState,
} from "./utils";
import { APIErrorResponse } from "./produt.types";
type Props = {};
// import "./form.scss";

export default function CreateForm({}: Props) {
  const [product, setProduct] = useState<any>(initialState);
  const params = useParams();
  const [categoryList, setCategoryList] = useState<AxiosServerResponse>({
    data: [],
    statusCode: 0,
    success: false,
    limit: 50,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [subCategoryList, setSubCategoryList] = useState<SubCategory[]>([]);

  async function fetchDataOnMount() {
    try {
      setLoading((pre) => true);
      const categoryResponse = await GetRequest(
        "v1/category?page=1&limit=50&type=Product",
      );
      //@ts-ignore
      setCategoryList((_pre: any) => categoryResponse);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((pre) => false);
    }
  }
  useEffect(() => {
    fetchDataOnMount();
  }, []);
  function handleDragStart(event: any) {
    event.dataTransfer.setData("text/plain", event.currentTarget.dataset.index);
  }

  function handleDragOver(event: any) {
    event.preventDefault();
  }
  async function handleFileChange(
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ): Promise<void | FormikErrors<any>>;
      (arg0: string, arg1: string[]): void;
    },
    preValue: string[],
    setFieldError: (field: string, message: string | undefined) => void,
  ) {
    const files = event.currentTarget.files;
    if (files) {
      if (!files[0].type.includes("image/")) {
        setFieldError("imageList", "only image file need to be uploaded");
        return;
      }
      if (files[0].size > 1048576) {
        setFieldError("imageList", "Image Size is More then 1Mb");
        return;
      }
      const formData1 = new FormData();
      formData1.append("file", files[0]);
      try {
        const fileResponse = await PostRequest(
          "v1/file?type=product-image",
          formData1,
        );
        setFieldValue("imageList", [...preValue, fileResponse.data.url]); // Update the state after modifying the image
        setFieldError("imageList", "");
      } catch (err: any) {
        setFieldError("imageList", JSON.stringify(err));
      }
    }
  }
  function handleDrop(
    event: React.DragEvent<HTMLElement>,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ): Promise<void | FormikErrors<any>>;
      (arg0: string, arg1: string[]): void;
    },
    preValue: string[],
  ) {
    event.preventDefault();

    const oldIndex = Number(event.dataTransfer.getData("text/plain"));
    const newIndex = Number(event.currentTarget.dataset.index);
    const maxIndex = preValue.length - 1;

    if (
      oldIndex < 0 ||
      oldIndex > maxIndex ||
      newIndex < 0 ||
      newIndex > maxIndex
    ) {
      return; // Invalid index, do not perform the replacement
    }

    if (oldIndex === newIndex) {
      return; // No need to perform the replacement if oldIndex and newIndex are the same
    }

    const productImage = [...preValue];
    const draggedImage = productImage[oldIndex];
    productImage.splice(oldIndex, 1); // Remove the dragged element
    productImage.splice(newIndex, 0, draggedImage); // Insert the dragged element at the drop position
    setFieldValue("imageList", productImage);
  }
  const handleWheel = (event: any) => {
    event.preventDefault();
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };
  return loading ? (
    <p>loading.....</p>
  ) : (
    <Formik
      initialValues={product}
      onSubmit={async (values, actions) => {
        let error = await actions.validateForm(values);
        if (Object.keys(error).length) {
          // return;
        }
        // console.log(finalValue)
        try {
          const response = await PostRequest(
            `v1/product/?brand=${params.brandId}`,
            removeEmptyElements(values),
          );
          setTimeout(() => actions.resetForm(), 1000);
        } catch (error: APIErrorResponse | any) {
          console.log(error);
          actions.setFieldError(
            error.fieldErrors[0].split(" ")[0],
            error.massage,
          );
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        handleChange,
        setFieldValue,
        setFieldError,
        errors,
        submitForm,
      }) => {
        console.log(errors);
        return (
          <Form>
            <div className="flex justify-center items-center">
              <div>
                <div className="grid gap-4 mb-4 grid-cols-6 w-full">
                  {/* 3 input box */}
                  <div className="col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Product Name *
                    </label>
                    <Field
                      onChange={handleChange}
                      type="text"
                      id="name"
                      name="name"
                      required
                      placeholder="Product Name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      name="name"
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                    />
                  </div>

                  <div className="col-span-2">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Description *
                    </label>
                    <Field
                      onChange={handleChange}
                      type="text"
                      id="description"
                      name="description"
                      required
                      placeholder="Description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="description"
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="mrp"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      MRP *
                    </label>
                    <Field
                      onChange={handleChange}
                      type="number"
                      onWheel={handleWheel}
                      onKeyDown={handleKeyDown}
                      name="mrp"
                      id="mrp"
                      placeholder="MRP"
                      required
                      className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="mrp"
                    />
                  </div>
                </div>
                <div style={{ marginTop: "1.6rem" }}>
                  <div className="mb-4 mt-2 w-[100%]">
                    <div className="mb-[-0.7rem] ml-[0.5rem]">
                      <label
                        htmlFor="shopImage"
                        className="text-sm bg-white px-[0.2rem] lg:text-xs font-semibold"
                      >
                        Product's Image
                      </label>
                    </div>

                    {/* card for showing uploaded images by the user */}
                    <div className="basis-[90%] sm:p-[0.5rem] flex flex-wrap rounded border-solid border-[1px] p-4 border-gray-220">
                      {values.imageList?.length > 0 &&
                        values?.imageList?.map((img: string, index: number) => (
                          <figure
                            key={index}
                            draggable
                            onDragStart={handleDragStart}
                            onDragOver={handleDragOver}
                            onDrop={(event) =>
                              handleDrop(event, setFieldValue, values.imageList)
                            }
                            data-index={index}
                            className="relative m-[0.4rem] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[black]"
                            style={{
                              width: "110px",
                              height: "110px",
                            }}
                          >
                            <span
                              onClick={() => {
                                let productImage = values.imageList.filter(
                                  (img: String, i: number) => {
                                    return i !== index;
                                  },
                                );
                                setFieldValue("imageList", productImage);
                              }}
                              style={{
                                position: "absolute",
                                right: "4px",
                                borderRadius: "50%",
                                display: "flex",
                                background: "white",
                                top: "5px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "20px",
                                height: "20px",
                              }}
                            >
                              <RxCross2 />
                            </span>
                            <img
                              src={img}
                              className="rounded-md w-full h-[110px] object-cover"
                              alt={"shop-image" + index}
                            />
                          </figure>
                        ))}
                      <button
                        className="w-[110px] m-[0.4rem] sm:m-[0.2rem] p-[24px] h-[110px] border-box bg-[#EAECEC] h-auto flex-col flex items-center justify-center border-solid border-[1px] border-gray-220 rounded-md"
                        style={{
                          width: "110px",
                          height: "110px",
                        }}
                      >
                        <i
                          style={{
                            backgroundImage:
                              'url("/images/add_photos_icon.png")',
                            backgroundPosition: "0px -58px",
                            backgroundSize: "auto",
                            color: "white",
                            width: "20px",
                            height: "20px",
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                          }}
                        ></i>
                        <span className="text-xs mt-2">Add Photos</span>
                        <input
                          type="file"
                          name="imageList"
                          style={{
                            position: "absolute",
                            opacity: "0",
                            cursor: "pointer",
                            width: "110px",
                            top: "100",
                            height: "110px",
                          }}
                          onChange={(event) =>
                            handleFileChange(
                              event,
                              setFieldValue,
                              values.imageList,
                              setFieldError,
                            )
                          }
                        />
                      </button>
                    </div>
                    <div style={{ marginTop: "0.5rem" }}>
                      {/* <span className="error"> {product.errors.image}</span> */}
                    </div>
                  </div>
                </div>
                {errors.imageList ? (
                  <div className="text-red-500 text-xs italic pl-2">
                    {/* @ts-ignore */}
                    {errors?.imageList}
                  </div>
                ) : null}
                <div className="grid gap-4 mb-4 grid-cols-4 w-full">
                  {/* two dropdown */}
                  <div className="col-span-2">
                    <label
                      htmlFor="categoryId"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Category *
                    </label>
                    <Field
                      as="select"
                      id="categoryId"
                      name="categoryId"
                      placeholder="Select category"
                      required
                      onChange={(event: any) => {
                        //@ts-ignore
                        setSubCategoryList(
                          (pre) =>
                            //@ts-ignore
                            categoryList?.data?.find(
                              (value) => value._id === event.target.value,
                            )?.subCategoryList ?? [],
                        );
                        handleChange(event);
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    >
                      <option value="">Select Category</option>
                      {categoryList.data.map((data) => (
                        <option
                          key={data._id}
                          selected={data._id === values.categoryId}
                          value={data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="categoryId"
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="subCategoryId"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Sub-Category *
                    </label>
                    <Field
                      as="select"
                      name="subCategoryId"
                      id="subCategoryId"
                      placeholder="Select Sub-Category"
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    >
                      <option value="">Select Sub-Category</option>
                      {subCategoryList.map((data) => (
                        <option
                          key={data._id}
                          selected={data._id === values.subCategoryId}
                          value={data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="subCategoryId"
                    />
                  </div>
                </div>
                <div className="grid gap-4 mb-4 grid-cols-4 w-full">
                  <div className="col-span-2">
                    <label
                      htmlFor="weight.unit"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Weight (Unit)
                    </label>
                    <Field
                      onChange={handleChange}
                      type="text"
                      name="weight.unit"
                      id="weight.unit"
                      placeholder="Weight (unit)"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="weight.unit"
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="weight.quantity"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Weight (quantity)
                    </label>
                    <Field
                      onChange={handleChange}
                      type="number"
                      onWheel={handleWheel}
                      onKeyDown={handleKeyDown}
                      id="weight.quantity"
                      name="weight.quantity"
                      placeholder="Weight (quantity)"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placehappearance-none older-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="weight.quantity"
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="size.unit"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Size (Unit)
                    </label>
                    <Field
                      onChange={handleChange}
                      type="text"
                      name="size.unit"
                      id="size.unit"
                      placeholder="Size (unit)"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="size.unit"
                    />
                  </div>

                  <div className="col-span-2">
                    <label
                      htmlFor="size.quantity"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Size (quantity)
                    </label>
                    <Field
                      onChange={handleChange}
                      type="number"
                      onWheel={handleWheel}
                      onKeyDown={handleKeyDown}
                      name="size.quantity"
                      id="size.quantity"
                      placeholder="Size (quantity)"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placehappearance-none older-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="size.quantity"
                    />
                  </div>
                </div>
                <div>
                  <FieldArray name="size.otherSizeVariantList">
                    {({ insert, remove, push }) => (
                      <div>
                        {values?.size?.otherSizeVariantList?.length > 0 &&
                          values?.size?.otherSizeVariantList?.map(
                            (otherSizeVariantList: any, index: number) => (
                              <div
                                className="grid gap-4 mb-4 grid-cols-9 w-full"
                                key={index}
                              >
                                <div className="col-span-4">
                                  <label
                                    htmlFor={`size.otherSizeVariantList.${index}.unit`}
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >
                                    Size (Unit)
                                  </label>
                                  <Field
                                    name={`size.otherSizeVariantList.${index}.unit`}
                                    placeholder="Unit"
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                  />
                                  <ErrorMessage
                                    className="text-red-500 text-xs italic pl-2"
                                    component={"div"}
                                    name={`size.otherSizeVariantList[${index}].unit`}
                                  />
                                </div>
                                <div className="col-span-4">
                                  <label
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    htmlFor={`size.otherSizeVariantList.${index}.quantity`}
                                  >
                                    Size (quantity)
                                  </label>
                                  <Field
                                    name={`size.otherSizeVariantList.${index}.quantity`}
                                    placeholder="quantity"
                                    type="number"
                                    onWheel={handleWheel}
                                    onKeyDown={handleKeyDown}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    appearance-none
                                  />
                                  <ErrorMessage
                                    className="text-red-500 text-xs italic pl-2"
                                    component={"div"}
                                    name={`size.otherSizeVariantList[${index}].quantity`}
                                  />
                                </div>
                                <div className="col-span-1 flex flex-1 justify-center items-center pt-7">
                                  <button
                                    type="button"
                                    className="text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 me-2 mb-2"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ),
                          )}
                        <button
                          type="button"
                          onClick={() => push({ unit: "", quantity: "" })}
                          className="text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 me-2 mb-2"
                        >
                          <svg
                            className="w-4 h-4 me-2 -ms-1 text-[#626890]"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="ethereum"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"
                            ></path>
                          </svg>
                          Add Other Size Variant
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>
                <div className="grid gap-4 mb-4 grid-cols-4 w-full">
                  <div className="col-span-2">
                    <label
                      htmlFor="color.name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Color
                    </label>
                    <Field
                      onChange={handleChange}
                      type="text"
                      name="color.name"
                      id="color.name"
                      placeholder="Color"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="color.name"
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="flavor.name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Flavor
                    </label>
                    <Field
                      onChange={handleChange}
                      type="text"
                      id="flavor.name"
                      name="flavor.name"
                      placeholder="Flavor"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="flavor.name"
                    />
                  </div>
                </div>
                <div className="grid gap-4 mb-4 grid-cols-6 w-full">
                  <div className="col-span-2">
                    <label
                      htmlFor="info.pieceInEachBundle"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      PieceInEachBundle
                    </label>
                    <Field
                      onChange={handleChange}
                      type="number"
                      onWheel={handleWheel}
                      onKeyDown={handleKeyDown}
                      id="info.pieceInEachBundle"
                      placeholder="PieceInEachBundle"
                      name="info.pieceInEachBundle"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placehappearance-none older-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="info.pieceInEachBundle"
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="info.pieceInEachBox"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      PieceInEachBox
                    </label>
                    <Field
                      onChange={handleChange}
                      type="number"
                      onWheel={handleWheel}
                      onKeyDown={handleKeyDown}
                      name="info.pieceInEachBox"
                      id="info.pieceInEachBox"
                      placeholder="PieceInEachBox"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placehappearance-none older-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="info.pieceInEachBox"
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="hsnCode"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      HSN Code *
                    </label>
                    <Field
                      onChange={handleChange}
                      type="number"
                      onWheel={handleWheel}
                      onKeyDown={handleKeyDown}
                      name="hsnCode"
                      id="hsnCode"
                      placeholder="HSN Code *"
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:bordeappearance-none r-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic pl-2"
                      component={"div"}
                      name="hsnCode"
                    />
                  </div>
                </div>
                <div>
                  <FieldArray name="taxPercentageList">
                    {({ insert, remove, push }) => (
                      <div>
                        <h1>Tax Percentage List</h1>
                        {values?.taxPercentageList?.length > 0 &&
                          values.taxPercentageList.map(
                            (_item: any, index: number) => (
                              <div
                                className="grid gap-4 mb-4 grid-cols-9 w-full"
                                key={index}
                              >
                                <div className="col-span-2">
                                  <label
                                    htmlFor={`taxPercentageList.${index}.type`}
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >
                                    Tax Type *
                                  </label>
                                  <Field
                                    as="select"
                                    name={`taxPercentageList.${index}.type`}
                                    id={`taxPercentageList.${index}.type`}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                  >
                                    <option value="">Select Tax Type</option>
                                    <option value="IGST">IGST</option>
                                    <option value="CGST">CGST</option>
                                    <option value="SGST">SGST</option>
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-500 text-xs italic pl-2"
                                    component={"div"}
                                    name={`taxPercentageList[${index}].type`}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <label
                                    htmlFor={`taxPercentageList.${index}.percentage`}
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >
                                    Percentage *
                                  </label>
                                  <Field
                                    type="number"
                                    onWheel={handleWheel}
                                    onKeyDown={handleKeyDown}
                                    name={`taxPercentageList.${index}.percentage`}
                                    id={`taxPercentageList.${index}.percentage`}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:borderappearance-none -gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                  />
                                  <ErrorMessage
                                    className="text-red-500 text-xs italic pl-2"
                                    component={"div"}
                                    name={`taxPercentageList[${index}].percentage`}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <label
                                    htmlFor={`taxPercentageList.${index}.condition.basedOn`}
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >
                                    BasedOn *
                                  </label>
                                  <Field
                                    as="select"
                                    name={`taxPercentageList.${index}.condition.basedOn`}
                                    id={`taxPercentageList.${index}.condition.basedOn`}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                  >
                                    <option value="">Select BasedOn</option>
                                    <option value="State">State</option>
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-500 text-xs italic pl-2"
                                    component={"div"}
                                    name={`taxPercentageList[${index}].condition.basedOn`}
                                  />
                                </div>
                                <div className="col-span-2">
                                  <label
                                    htmlFor={`taxPercentageList.${index}.condition.is`}
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >
                                    Condition Is *
                                  </label>
                                  <Field
                                    as="select"
                                    name={`taxPercentageList.${index}.condition.is`}
                                    id={`taxPercentageList.${index}.condition.is`}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                  >
                                    <option value="">Select Condition</option>
                                    <option value="Same">Same</option>
                                    <option value="Different">Different</option>
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-500 text-xs italic pl-2"
                                    component={"div"}
                                    name={`taxPercentageList[${index}].condition.is`}
                                  />
                                </div>
                                <div className="col-span-1 flex flex-1 justify-center items-center pt-7">
                                  <button
                                    type="button"
                                    className="text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 me-2 mb-2"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ),
                          )}
                        <button
                          type="button"
                          onClick={() =>
                            push({
                              condition: { basedOn: "", is: "" },
                              percentage: 0,
                              type: "",
                            })
                          }
                          className="text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 me-2 mb-2"
                        >
                          <svg
                            className="w-4 h-4 me-2 -ms-1 text-[#626890]"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="ethereum"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"
                            ></path>
                          </svg>
                          Add Tax Row
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>
            <div className="gap-4 mb-4 grid-cols-9 w-full flex flex-1 justify-center item-center">
              <button
                disabled={isSubmitting}
                type="submit"
                className="w-56 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
              >
                Submit
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
