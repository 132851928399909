import "./App.css";
import "./index.css";
import {
  fetchUserInfoAsyncThunk,
  isLoginSelector,
} from "./features/auth/Slice";
import { useDispatch, useSelector } from "react-redux";
import Login from "./page/auth/login/Login";
import { useEffect } from "react";
import { AppDispatch } from "./store";
import RoutePage from "./routes/Router";
function App(props: any) {
  const isLoggedIn = useSelector(isLoginSelector);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    // if(isLoggedIn)
    dispatch(fetchUserInfoAsyncThunk());
  }, [isLoggedIn]);
  return isLoggedIn ? (
    <div className="flex:1">
      <RoutePage />
    </div>
  ) : (
    <Login />
  );
  // return <div className="flex:1"><RoutePage/></div>
}

export default App;
