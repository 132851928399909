import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostRequest, GetRequest } from "../../api/Request";
import { AsyncThunkConfig, RootState } from "../../store";
export interface IAuthState {
  user: {
    _id: string;
    username: string;
    phoneNumber: string;
    roleList?: string[];
    email?: string;
    name: string;
    profileImage: string;
    dateOfBirth?: string;
    gender?: string;
    loading: "Pending" | "Fulfiled";
    isLogedIn: boolean;
  };
}
const initialState: IAuthState = {
  user: {
    _id: "",
    username: "",
    phoneNumber: "",
    roleList: [],
    email: "",
    name: "",
    profileImage: "",
    dateOfBirth: "",
    gender: "",
    loading: "Fulfiled",
    isLogedIn: false,
  },
};

export const logOutAsyncThunk = createAsyncThunk<
  any,
  undefined,
  AsyncThunkConfig
>("user/logout", async (a: undefined, { rejectWithValue }) => {
  try {
    const response = await PostRequest("v1/auth/log-out", {});
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const loginAsynkThunk = createAsyncThunk<
  IAuthState,
  any,
  AsyncThunkConfig
>("user/login", async (value, { rejectWithValue }) => {
  try {
    const response = await PostRequest("v1/auth/log-in", value);
    return response.data as IAuthState;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const fetchUserInfoAsyncThunk = createAsyncThunk<
  IAuthState,
  undefined,
  AsyncThunkConfig
>("user/fetchUser", async (value: undefined, { rejectWithValue }) => {
  try {
    const response = await GetRequest("v1/user/self");
    return response.data as IAuthState;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addIsLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.user.loading = "Pending";
      state.user.isLogedIn = Boolean(payload);
      state.user.loading = "Fulfiled";
    },
    addUserInfoFromLoacalStore: (
      state,
      { payload }: PayloadAction<IAuthState>,
    ) => {
      state.user.loading = "Pending";
      state = { ...payload };
      state.user.loading = "Fulfiled";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfoAsyncThunk.pending, (state) => {
        state.user.loading = "Pending";
      })
      .addCase(
        fetchUserInfoAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<IAuthState>) => {
          state.user.isLogedIn = true;
          state.user.loading = "Fulfiled";
        },
      )
      .addCase(fetchUserInfoAsyncThunk.rejected, (state, { payload }) => {
        state = { ...initialState };
        state.user.loading = "Fulfiled";
      })
      .addCase(loginAsynkThunk.pending, (state) => {
        state.user.loading = "Pending";
      })
      .addCase(
        loginAsynkThunk.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.user = payload;
          state.user.isLogedIn = true;
          state.user.loading = "Fulfiled";
        },
      )
      .addCase(loginAsynkThunk.rejected, (state) => {
        state.user = initialState.user;
        state.user.isLogedIn = false;
      })
      .addCase(logOutAsyncThunk.pending, (state) => {
        state.user.loading = "Pending";
      })
      .addCase(logOutAsyncThunk.fulfilled, (state) => {
        state.user = initialState.user;
      });
  },
});

// Action creators are generated for each case reducer function
export const { addIsLogin, addUserInfoFromLoacalStore } = AuthSlice.actions;

export default AuthSlice.reducer;

export const isLoginSelector = (state: RootState) => state.auth.user.isLogedIn;
export const userSelector = (state: RootState) => state.auth.user;
export const loadingSelector = (state: RootState) => state.auth.user.loading;
export const phoneNumberListSelector = (state: RootState) =>
  state.auth.user.phoneNumber;
