export function getValueByPath(obj: any, path: string) {
  // Split the path into an array of keys
  const keys = path.split(".");

  // Traverse the object step by step
  return keys.reduce((acc, key) => acc && acc[key], obj);
}

export function setValueByPath(obj: any, path: string, value: any): any {
  const keys = path.split(".");
  let current = obj;

  keys.forEach((key, index) => {
    // If we're at the last key, set the value
    if (index === keys.length - 1) {
      current[key] = value;
    } else {
      // If the key doesn't exist, create an empty object or array
      if (!current[key]) {
        current[key] = isNaN(Number(keys[index + 1])) ? {} : [];
      }
      current = current[key];
    }
  });

  return obj;
}
