import React, { ChangeEvent, useEffect, useState } from "react";
// import './FormInput.scss'
import { RxCross2 } from "react-icons/rx";
import { FormField } from "../../Form";
import { PostRequest } from "../../../../api/Request";

interface FormFileInputProps {
  formField: FormField;
  // label: string | undefined;
  // type: string;
  // name: string;
  parentKey: string;
  value: any;
  handleChange: (name: string, value: any) => void;
  // errorMessage?: string;
  // file?: { api: { apiPath: string; method: string } };
  // required?: boolean;
  // placeholder?: string;
  // readOnly?: boolean;
}

const FormFileInput: React.FC<FormFileInputProps> = ({
  formField,
  // label,
  // type,
  // name,
  parentKey,
  value,
  handleChange,
  // errorMessage,
  // file,
  // required,
  // placeholder,
  // readOnly
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileData = event.target.files?.[0];
    if (fileData && formField.file?.api.apiPath) {
      const formData = new FormData();
      formData.append("file", fileData);
      try {
        const response: any = await PostRequest(
          formField.file.api.apiPath,
          formData,
        );
        handleChange(parentKey, response.data.url);
      } catch (error) {
        setError("File upload failed");
      }
    }
  };

  useEffect(() => {
    if (formField.required && !value) {
      setError("This field is required");
    } else {
      setError(null);
    }
  }, [value, formField.required]);

  return (
    <div className="formInput">
      <label>{formField.label}: </label>
      {value ? (
        <figure
          //  key={index}
          draggable
          //  onDragStart={handleDragStart}
          //  onDragOver={handleDragOver}
          //  onDrop={handleDrop}
          //  data-index={index}
          className="relative m-[0.4rem] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[black]"
          style={{
            width: "110px",
            height: "110px",
          }}
        >
          <span
            onClick={() => {
              handleChange(parentKey, "");
              //  let productImage = product.image.filter(
              //    (img: String, i: number) => {
              //      return i !== index;
              //    },
              //  );
              //  setProduct({
              //    ...product,
              //    image: productImage,
              //  });
            }}
            style={{
              position: "absolute",
              right: "4px",
              borderRadius: "50%",
              display: "flex",
              background: "white",
              top: "5px",
              justifyContent: "center",
              alignItems: "center",
              width: "20px",
              height: "20px",
            }}
          >
            <RxCross2 />
          </span>
          <img
            src={value}
            className="rounded-md w-full h-[110px] object-cover"
            //  alt={"shop-image" + index}
          />
        </figure>
      ) : (
        <input
          type="file"
          name={formField.name}
          onChange={handleFileChange}
          required={formField.required}
          readOnly={formField.readOnly}
        />
      )}
      {error && <span className="error">{error}</span>}
      {formField.errorMessage && !value && (
        <span className="error">{formField.errorMessage}</span>
      )}
    </div>
  );
};

export default FormFileInput;
