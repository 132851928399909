import { useEffect, useState, useRef } from "react";
import { GetRequest } from "../../../api/Request";
import { FiSearch } from "react-icons/fi";
import "./storeList.scss";
import { IStore } from "./store.types";
import StoreForm from "./storeForm/storeForm";

export default function Store(_props: any) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumberAccToSearchedValue, setPageNumberAccToSearchedValue] =
    useState(1);
  const [limit, setLimit] = useState(10);
  const [store, setStore] = useState<IStore[]>([]);
  const storeListRef = useRef(null);
  const [hasMoreStore, setHasMoreStore] = useState<any>(true);
  const [searchedValueForStore, setSearchedValueForStore] = useState<any>("");
  const [storeDetail, setStoreDetail] = useState<IStore>();
  const [formModal, setFormModal] = useState<boolean>(false);
  const onIntersectionForStoreListRef = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreStore) {
      console.log(searchedValueForStore, "therr");
      if (searchedValueForStore) {
        setPageNumberAccToSearchedValue(
          (prevPageNumberAccToSearchedValue: any) => {
            return prevPageNumberAccToSearchedValue + 1;
          },
        );
      }
      if (searchedValueForStore == "") {
        setPageNumber((prevPageNumber: any) => {
          return prevPageNumber + 1;
        });
      }
    }
  };

  useEffect(() => {
    if (storeListRef.current) {
      const observer = new IntersectionObserver(onIntersectionForStoreListRef);
      observer.observe(storeListRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [hasMoreStore, store.length > 0]);

  useEffect(() => {
    if (pageNumberAccToSearchedValue > 1) {
      getStoreAccToSearchedValue(searchedValueForStore);
    }
  }, [pageNumberAccToSearchedValue]);

  useEffect(() => {
    if (pageNumber > 1) {
      getStore();
    }
  }, [pageNumber, formModal]);

  useEffect(() => {
    getStore();
  }, []);

  // const getStore = async () => {
  //   await GetRequest(`store?limit=${limit}&page=${pageNumber}`)
  //     .then(async (res) => {
  //       setStore((pre) =>
  //         res.data.map((data: any) => {
  //           return { ...data, isActionMenuOpen: false };
  //         }),
  //       );
  //       setHasMoreStore((pre: boolean) =>
  //         res.data.length < limit ? false : true,
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err, "this is err in fetching all store ");
  //     });
  // };
  const getStore = async () => {
    await GetRequest(`v1/store?limit=${limit}&page=${pageNumber}`)
      .then(async (res) => {
        const allStore = res.data.map((data: any) => {
          return { ...data, isActionMenuOpen: false };
        });

        if (res.data.length < limit) {
          setHasMoreStore(false);
        }

        setStore((prevState: any) => {
          return [...prevState, ...allStore];
        });
      })
      .catch((err) => {
        console.log(err, "Error fetching all stores");
      });
  };

  const getStoreAccToSearchedValue = async (value: any) => {
    await GetRequest(
      !value
        ? `v1/store?limit=${limit}&page=${pageNumberAccToSearchedValue}`
        : `v1/store?name=${value}&limit=${limit}&page=${pageNumberAccToSearchedValue}`,
    )
      .then(async (res) => {
        console.log(res.data[0]);
        let allStore = res.data.map((data: any) => {
          return { ...data, isActionMenuOpen: false };
        });

        if (res.data.data.length === limit) {
          setHasMoreStore((prevValue: any) => true);
        }

        if (res.data.data.length < limit) {
          setHasMoreStore((prevValue: any) => false);
        }

        if (pageNumberAccToSearchedValue === 1) {
          setStore((prevState: any) => {
            return [...allStore];
          });
        }

        if (pageNumberAccToSearchedValue > 1) {
          setStore((prevState: any) => {
            return [...prevState, ...allStore];
          });
        }
      })
      .catch((err) => {
        console.log(err, "this is err in fetching all store while searching ");
      });
  };

  const handleChangeOnSearchedValue = async (event: any) => {
    let { value } = event.target;
    setPageNumberAccToSearchedValue(1);

    setSearchedValueForStore((prevState: any) => value);
    await getStoreAccToSearchedValue(value);
  };

  return (
    <>
      <div className="parent_cnt_order_page">
        <div
          className="order_page_big_cnt"
          style={{
            position: "relative",
          }}
        >
          <div className="search-bar-component">
            <div className="search-bar">
              <input
                type="text"
                name="product-name"
                placeholder="Search Store..."
                onChange={handleChangeOnSearchedValue}
                value={searchedValueForStore}
              />
              <button className="search-icon">
                <FiSearch />
              </button>
            </div>
          </div>
          {/* {formModal && (
        <StoreForm
          showModal={formModal}
          store={storeDetail}
          updateStore={() => {}}
          closeModal={()=> setFormModal(false)}
        />
      )} */}
        </div>
        <div
          style={{
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <table className="store-list-custom-table">
            <thead>
              <tr>
                <th>Profile Picture</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            {store.length > 0 &&
              store.map((data: any, index: number) => {
                return (
                  <tbody key={data._id}>
                    <tr>
                      <td className="store-profile-cnt">
                        <img
                          className="store-profile-pic"
                          src={data.profileImage}
                          alt={data.name}
                        />
                      </td>
                      <td>{data.name}</td>
                      <td>
                        <h2
                          className="hover:cursor-pointer text-blue-900"
                          onClick={() => {
                            setFormModal((pre) => true);
                            setStoreDetail((pre) => data);
                          }}
                        >
                          {" "}
                          Verify
                        </h2>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            {store.length > 0 && (
              <div ref={storeListRef} style={{ padding: "10px" }}></div>
            )}
          </table>
        </div>
      </div>
      {formModal && (
        <StoreForm
          showModal={formModal}
          store={storeDetail}
          updateStore={() => {}}
          closeModal={() => setFormModal(false)}
        />
      )}
    </>
  );
}
