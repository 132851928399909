export enum FormInputTypeEnum {
  Text = "text",
  Number = "number",
  Email = "email",
  Select = "select",
  MultiSelect = "multi-select",
  File = "file",
  Object = "object",
  Array = "array",
}
