import Form from "../../../../component/form/Form";
import { FormInputTypeEnum } from "../../../../enum";
import { IBrand } from "../brand.types";
import "./BrandForm.scss";

export default function BrandForm({
  data,
  showModal,
  updateStore,
  closeModal,
}: {
  data: IBrand | undefined;
  // IStore;
  showModal: boolean;
  updateStore: () => void;
  closeModal: any;
  // Dispatch<SetStateAction<boolean>>;
}) {
  let submitDetail = {
    path: `admin/v1/brand`,
    method: "POST",
    message: "Form Submitted Successfully",
  };
  if (data?._id) {
    submitDetail = {
      path: `admin/v1/brand/${data?._id}`,
      method: "PATCH",
      message: "Form Submitted Successfully",
    };
  }
  return (
    <div>
      {/* {
        openForm &&  */}
      <Form
        columns={4}
        formField={[
          {
            name: "name",
            label: "Brand Name",
            type: FormInputTypeEnum.Text,
            required: true,
          },
          {
            name: "description",
            label: "Description",
            type: FormInputTypeEnum.Text,
          },
          {
            name: "email",
            label: "Email",
            type: FormInputTypeEnum.Email,
            // required: true,
          },
          {
            name: "website",
            label: "Website",
            type: FormInputTypeEnum.Text,
          },
          {
            name: "phoneNumberList",
            label: "Phone Number",
            type: FormInputTypeEnum.Array,
            formField: [
              {
                name: "label",
                label: "label",
                type: FormInputTypeEnum.Text,
              },
              {
                name: "countryCode",
                label: "countryCode",
                type: FormInputTypeEnum.Text,
              },
              {
                name: "nationalNumber",
                label: "nationalNumber",
                type: FormInputTypeEnum.Text,
              },
            ],
          },
          {
            name: "logo",
            label: "logo",
            type: FormInputTypeEnum.File,
            file: {
              api: { apiPath: `v1/file?type=brand-image`, method: "POST" },
            },
          },
          {
            name: "email",
            label: "Email",
            type: FormInputTypeEnum.Email,
          },
        ]}
        formValues={data}
        // specificData={specificData}
        submitDetail={submitDetail}
        operation={data?._id ? "update" : "create"}
        closeModal={closeModal}
      />
    </div>
  );
}
