import {
  string as str,
  number as num,
  array,
  object as YupObject,
  mixed,
} from "yup";
//@ts-ignore
const schema = YupObject().when("/", {
  is: (size: any) => size && Object.keys(size).length > 0, // Check if size exists and is not an empty object
  then: YupObject().shape({
    quantity: str().required("Quantity is required"), // Required if size exists
    unit: str().required("Unit is required"), // Required if size exists
  }),
});
export const validationSchema = YupObject().shape({
  name: str()
    .min(3, "Name must have more then 2 char")
    .max(200, "maximum 200 character allowed")
    .required("Name is required"),
  description: str().required("description is required"),
  mrp: num().min(0.001).required("MRP is required"),
  hsnCode: num().required("HSN-CODE is required"),
  info: YupObject({
    pieceInEachBundle: num().optional(),
    pieceInEachBox: num().optional(),
  }),
  size: schema,
  // YupObject({
  //     unit: str().optional(),
  //     //@ts-ignore
  //     quantity: str().when("size.unit", {
  //         is: (val: any) => !!val || val !== undefined,
  //         then: num().required("Quantity is required if Unit is provided."),
  //     }),
  // }),
  //   otherSizeVariantList: array()
  //     .optional()
  //     .of(
  //       YupObject({
  //         quantity: num().test(
  //           "required-when-unit-is-present",
  //           "Quantity is required when unit is specified",
  //           function (value: any, context: any) {
  //             console.log(value);
  //             const unit = context.parent.unit;
  //             return unit !== "" || typeof value === "undefined";
  //           }
  //         ),
  //         unit: str().optional(),
  //       })
  //     ),
  // }),
  weight: schema,
  //  YupObject({
  //     quantity: num().test(
  //         "required-when-unit-is-present",
  //         "Quantity is required when unit is specified",
  //         function (value: any, context: any) {
  //             const unit = context.parent.unit;
  //             return unit !== "" || typeof value === "undefined";
  //         }
  //     ),
  //     unit: str().optional(),
  // }),
  color: YupObject({
    name: str().optional(),
  }),
  flavor: YupObject({
    name: str().optional(),
  }),
  imageList: array().of(str().optional()),
  taxPercentageList: array().of(
    YupObject({
      type: str().required(),
      condition: YupObject({
        basedOn: str().required(),
        is: str().optional(),
      }),
      percentage: num().required(),
    }),
  ),
  categoryId: str().optional(),
  subCategoryId: str().optional(),
});

export const getUpdatedValues = (
  currentValues: Record<string, any>,
  initialValues: Record<string, any>,
  prefix = "",
): Record<string, any> => {
  const updatedValues: Record<string, any> = {};

  // Get all unique keys from currentValues and initialValues
  const allKeys: any = new Set([
    ...Object.keys(currentValues),
    ...Object.keys(initialValues),
  ]);

  // Iterate over each key
  for (const key of allKeys) {
    const currentKey = prefix ? `${prefix}.${key}` : key;
    const currentValue = currentValues[key];
    const initialValue = initialValues[key];

    // Check if the key exists in both currentValues and initialValues
    if (currentValue !== undefined && initialValue !== undefined) {
      // If the value is an object, recursively check nested values
      if (typeof currentValue === "object" && !Array.isArray(currentValue)) {
        const nestedUpdatedValues = getUpdatedValues(
          currentValue,
          initialValue,
          currentKey,
        );
        // Include nested updated values if they exist
        if (Object.keys(nestedUpdatedValues).length > 0) {
          updatedValues[key] = { ...currentValue, ...nestedUpdatedValues };
        }
      } else if (Array.isArray(currentValue) && Array.isArray(initialValue)) {
        // Handle arrays
        if (JSON.stringify(currentValue) !== JSON.stringify(initialValue)) {
          updatedValues[key] = currentValue;
        }
      } else if (currentValue !== initialValue) {
        // If the value has changed, include it in updatedValues
        updatedValues[key] = currentValue;
      }
    } else if (currentValue !== undefined) {
      // If the key exists only in currentValues, include it in updatedValues
      updatedValues[key] = currentValue;
    }
    // If the key exists only in initialValues, it means it has been deleted
    // We don't need to do anything in this case because it won't be included in updatedValues
  }

  return updatedValues;
};
export interface NestedObject {
  [key: string]: NestedObject | any;
}
export function removeEmptyElements(obj: NestedObject): NestedObject {
  const newObj: NestedObject = {};

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      const newArray = obj[key]
        .map((item: any) => {
          if (typeof item === "object" && item !== null) {
            return removeEmptyElements(item);
          }
          return item;
        })
        .filter((item: any) => item !== undefined && item !== null);

      if (newArray.length !== 0) {
        newObj[key] = newArray;
      }
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      const nested = removeEmptyElements(obj[key] as NestedObject);
      if (Object.keys(nested).length !== 0) {
        newObj[key] = nested;
      }
    } else if (obj[key] !== undefined && obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}

export function removeEmptyObjects(obj: NestedObject): NestedObject {
  const newObj: NestedObject = {};
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      if (obj[key].length > 0) {
        newObj[key] = obj[key].map((item: any) => {
          if (typeof item === "object") {
            return removeEmptyObjects(item);
          }
          return item;
        });
      }
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      const nested = removeEmptyObjects(obj[key] as NestedObject);
      if (Object.keys(nested).length !== 0 || Array.isArray(nested)) {
        newObj[key] = nested;
      }
    } else if (obj[key] !== undefined && obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export const initialState = {
  name: "",
  description: "",
  mrp: 0,
  hsnCode: 0,
  info: {},
  size: {},
  weight: {},
  color: {},
  flavor: {},
  imageList: [],
  taxPercentageList: [],
  categoryId: "",
  subCategoryId: "",
};
