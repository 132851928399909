import React, { useEffect, useState } from "react";
import { PatchRequest, PostRequest, GetRequest } from "../../../../api/Request";
import { useParams } from "react-router";
import { error } from "console";
import { toast } from "react-toastify";

type Props = {};

function CreateBrand({}: Props) {
  const { brandId } = useParams();
  const [brand, setBrand] = useState<Record<string, any>>({});
  const [initialValue, setIinitialValue] = useState<Record<string, any>>({});
  function handleChange(
    params: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>,
  ) {
    if (params.target.name === "nationalNumber") {
      setBrand((pre) => ({
        ...brand,
        phoneNumberList: [
          {
            countryCode: "+91",
            nationalNumber: params.target.value,
            label: "Work",
          },
        ],
      }));
    } else if (params.target.name === "logo") {
      let img = params.target.files[0];
      const formData = new FormData();
      formData.append("file", img);
      PostRequest(`v1/file?type=brand-image`, formData)
        .then((res) => {
          setBrand((pre) => ({ ...pre, logo: res.data.url }));
        })
        .catch((e) => {});
    } else
      setBrand((pre) => ({
        ...pre,
        [params.target.name]: params.target.value,
      }));
  }

  useEffect(() => {
    GetRequest(`v1/brand/${brandId}`)
      .then((res) => {
        setIinitialValue((pre) => res.data);
      })
      .catch((e) => {
        //  console.log(e)
      });
  }, [brandId]);
  async function handleAddNewOrUpdateProduct() {
    try {
      if (brand.update === "on") {
        const updateResponse = await PatchRequest(`v1/brand/${brandId}`, {
          ...initialValue,
          ...brand,
        });
        toast.success(" brand updated Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // const response = await PostRequest("brand", brand);
        // console.log({response})
      }
    } catch (error) {}
  }
  return (
    <div>
      <form className="max-w-2xl mx-auto">
        <h1 className="text-3xl">Add New Brand</h1>

        <div className="flex items-center">
          <input
            id="checked-checkbox"
            type="checkbox"
            name="update"
            onChange={handleChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="checked-checkbox"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            You Want to Update the Brand
          </label>
        </div>

        <div className="mb-5">
          <label
            htmlFor="username-success"
            className="block mb-2 text-sm font-mediu "
          >
            *Brand name
          </label>
          <input
            value={brand?.name ?? initialValue?.name}
            type="text"
            onChange={handleChange}
            id="username-success"
            name="name"
            className="block  w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            // lock mb-2 text-sm font-medium text-red-700 dark:text-red-500
            placeholder="Bonnie Green"
            required={true}
          />
          <p className="block mb-2 text-sm font-mediu ">
            <span className="font-medium">Alright!</span> Username available!
          </p>
        </div>

        <label htmlFor="message" className="block mb-2 text-sm font-mediu ">
          Brand Discription
        </label>
        <textarea
          id="message"
          name="description"
          value={brand?.description ?? initialValue?.description}
          rows={4}
          className="block  w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Leave a comment..."
          onChange={handleChange}
        />
        <div>
          <label htmlFor="email-address-icon">Your Email</label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 16"
              >
                <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
              </svg>
            </div>
            <input
              value={brand?.email ?? initialValue?.email}
              type="text"
              id="email-address-icon"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@gamil.com"
              name="email"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-5">
          <label htmlFor="countries">Select an option</label>
          <select
            id="countries"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected={true}>Choose a country</option>
            <option value="US">Work</option>
          </select>
          <label
            htmlFor="username-success"
            className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500"
          >
            Phone Number
          </label>
          <input
            // value={initialValue?.phoneNumberList[0]?.nationalNumber}
            type="text"
            id="username-success"
            name="nationalNumber"
            onChange={handleChange}
            className="block  w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            // lock mb-2 text-sm font-medium text-red-700 dark:text-red-500
            placeholder="Enter Brand Phone Number"
          />
          <p className="block mb-2 text-sm font-mediu ">
            <span className="font-medium">Alright!</span> Username available!
          </p>
        </div>
        <div>
          <label htmlFor="website">Website URL</label>
          <input
            value={brand?.websiteUrl ?? initialValue?.websiteUrl}
            type="url"
            id="website"
            name="websiteUrl"
            onChange={handleChange}
            className="block  w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="flowbite.com"
          />
        </div>

        <div>
          <label htmlFor="user_avatar">Brand Logo</label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            aria-describedby="user_avatar_help"
            id="user_avatar"
            type="file"
            name="logo"
            onChange={handleChange}
          />
          <div
            className="mt-1 text-sm text-gray-500 dark:text-gray-300"
            id="user_avatar_help"
          >
            Brand Image
          </div>
          {brand?.logo && <img src={brand?.logo} className="w-36 h-36" />}
          {initialValue?.logo && (
            <img src={initialValue?.logo} className="w-36 h-36" />
          )}
        </div>
        <button
          type="button"
          className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          onClick={handleAddNewOrUpdateProduct}
        >
          Add Brand
        </button>
      </form>
    </div>
  );
}

export default CreateBrand;
