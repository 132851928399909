import { useState } from "react";
import { PatchRequest, PostRequest, PutRequest } from "../../api/Request";
import FormInput from "./formInput/FormInput";
import FormSelectInput from "./formInput/formSelectInput/FormSelectInput";
import FormArrayInput from "./formInput/formArrayInput/FormArrayInput";
import FormPopupModal from "./formPopupModal/FormPopupModal";
import { setValueByPath } from "../../util/util";
import { FormInputTypeEnum } from "../../enum";
import "./Form.scss";
import { isEmpty } from "lodash";
import FormFileInput from "./formInput/formFileInput/FormFileInput";

export interface FormField {
  name: string;
  type: FormInputTypeEnum;
  label: string;
  initialValue?: string; // todo: add initial value
  optionList?: { viewValue: string; value: any }[];
  file?: { api: { apiPath: string; method: string }; acceptType?: string };
  // type?: string;
  formField?: FormField[];
  // field?: FormField[];
  errorMessage?: string;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
}

interface FormProps {
  formField: FormField[];
  submitDetail: { path: string; method: string; message: string };
  operation: "create" | "update";
  closeModal: () => void;
  columns: number;
  updateKeysOnly?: boolean;
  formValues?: any;
  handleFieldChange?: (name: string, value: any) => void;
}

const Form: React.FC<FormProps> = ({
  formField,
  submitDetail,
  operation,
  closeModal,
  columns,
  updateKeysOnly = true,
  formValues = {},
  handleFieldChange,
}) => {
  const initialValues = JSON.parse(JSON.stringify(formValues));
  const [localFormValues, setLocalFormValues] = useState<any>(initialValues);
  const [error, setError] = useState<{ [key: string]: string }>({});
  // console.log(localFormValues, "localFormValues update on each change", initialValues)
  const handleChange = (name: string, value: any) => {
    const updatedValues = { ...localFormValues, [name]: value };
    setLocalFormValues(updatedValues);
    if (handleFieldChange) {
      handleFieldChange(name, value);
    }
  };

  // const handleArrayChange = (name: string, values: any) => {
  //   handleChange(name, values);
  // };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    formField.forEach((field) => {
      if (field.required && !localFormValues[field.name]) {
        newErrors[field.name] = "This field is required";
      }
    });
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    if (!submitDetail) return;
    event.preventDefault();
    if (!validateFields()) {
      return;
    }

    const dataToSubmit = updateKeysOnly
      ? Object.keys(localFormValues).reduce((updatedKeys: any, key: string) => {
          if (localFormValues[key] !== initialValues[key]) {
            updatedKeys[key] = localFormValues[key];
          }
          return updatedKeys;
        }, {})
      : localFormValues;
    if (isEmpty(dataToSubmit)) {
      alert("No changes to submit");
    } else {
      if (submitDetail.method === "POST") {
        await PostRequest(submitDetail.path, dataToSubmit);
      } else if (submitDetail.method === "PATCH") {
        await PatchRequest(submitDetail.path, dataToSubmit);
      } else if (submitDetail.method === "PUT") {
        await PutRequest(submitDetail.path, dataToSubmit);
      }
      alert(submitDetail.message);
      closeModal();
    }
  };

  return (
    <FormPopupModal closeModal={closeModal}>
      <form onSubmit={handleSubmit}>
        <div className="formGrid">
          {RenderFormFields({
            formField: formField ?? [],
            localFormValues: localFormValues,
            setLocalFormValues,
            // handleChange: handleChange,
            // handleChange: {props.handleChange(field.name, props.localFormValues[field.name])},
            columns: columns,
            error: error,
          })}
        </div>
        <button type="submit" className="formButton">
          Submit
        </button>
      </form>
    </FormPopupModal>
  );
};
export default Form;

export function RenderFormFields(props: {
  formField: FormField[];
  localFormValues: any;
  // handleChange: (name: string, value: any) => void;
  columns: number;
  error: any;
  handleFieldChange?: (name: string, value: any) => void;
  setLocalFormValues: (value: any) => void; // todo
  parentKey?: string;
}) {
  function getfieldName(currentFieldName: string) {
    return props.parentKey
      ? props.parentKey + "." + currentFieldName
      : currentFieldName;
  }
  const handleChange = (name: string, value: any) => {
    // const updatedValues = { ...props.localFormValues, [name]: value };
    // props.localFormValues = updatedValues
    props.setLocalFormValues((prevState: any) =>
      setValueByPath({ ...prevState }, name, value),
    );
    if (props.handleFieldChange) {
      props.handleFieldChange(name, value);
    }
  };
  return props.formField.map((field, index) => {
    const value = props.localFormValues
      ? props.localFormValues[field.name]
      : field.initialValue ?? undefined;
    switch (field.type) {
      case "text":
      case "number":
      case "email":
        return (
          <div
            key={index}
            className="formField"
            style={{ flex: `0 0 ${100 / props.columns}%` }}
          >
            <FormInput
              formField={field}
              // label={field.label}
              // type={field.type}
              parentKey={getfieldName(field.name)}
              // name={field.name}
              value={value}
              handleChange={handleChange}
              // errorMessage={field.errorMessage}
              // file={field.file}
              // required={field.required}
              // placeholder={field.placeholder}
            />
            {props.error[field.name] && (
              <span className="error">{props.error[field.name]}</span>
            )}
          </div>
        );
      case "file":
        return (
          <div
            key={index}
            className="formField"
            style={{ flex: `0 0 ${100 / props.columns}%` }}
          >
            <FormFileInput
              formField={field}
              // label={field.label}
              // type={field.type}
              parentKey={getfieldName(field.name)}
              // name={field.name}
              value={value}
              handleChange={handleChange}
              // errorMessage={field.errorMessage}
              // file={field.file}
              // required={field.required}
              // placeholder={field.placeholder}
            />
            {props.error[field.name] && (
              <span className="error">{props.error[field.name]}</span>
            )}
          </div>
        );
      case "select":
      case "multi-select":
        return (
          <div
            key={index}
            className="formField"
            style={{ flex: `0 0 ${100 / props.columns}%` }}
          >
            <FormSelectInput
              formField={field}
              // type={field.type}
              // name={field.name}
              parentKey={getfieldName(field.name)}
              value={value} // todo: array or not for multiple select
              handleChange={handleChange}
              // options={field.options || []}
              // required={field.required}
            />
            {props.error[field.name] && (
              <span className="error">{props.error[field.name]}</span>
            )}
          </div>
        );
      case "object":
        return (
          <>
            {/* <label>{field.label}: </label> */}
            <div className="objectField">
              <label>{field.label}: </label>
              <div
                key={index}
                className="formField"
                style={{
                  paddingLeft: "20px",
                  marginTop: "20px",
                  clear: "both",
                }}
              >
                {RenderFormFields({
                  formField: field.formField ?? [],
                  localFormValues: value,
                  setLocalFormValues: props.setLocalFormValues,
                  parentKey: getfieldName(field.name),
                  // handleChange: props.handleChange,
                  // handleChange: handleChange(field.name, props.localFormValues[field.name]),
                  columns: props.columns,
                  error: props.error,
                  handleFieldChange: props.handleFieldChange,
                })}
              </div>
            </div>
          </>
        );
      case "array":
        return (
          <div className="arrayField">
            <div key={index} className="formField">
              <FormArrayInput
                formField={field}
                // label={field.label}
                // name={field.name}
                parentKey={getfieldName(field.name)}
                values={value || []}
                setLocalFormValues={props.setLocalFormValues}
                handleChange={handleChange}
                // type={field.type || 'text'}
                // formField={field.formField}
                // file={field.file}
                // errorMessage={field.errorMessage}
                // required={field.required}
                // placeholder={field.placeholder}
                columns={props.columns}
                handleFieldChange={props.handleFieldChange}
                error={props.error}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  });
}
