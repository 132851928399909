import { useState } from "react";
import Form from "../../../../component/form/Form";
import { FormInputTypeEnum } from "../../../../enum";
import { StoreCategoryList, StoreTypeList } from "../../../../constant";

export default function StoreForm({
  store,
  showModal,
  updateStore,
  closeModal,
}: {
  store: any;
  // IStore;
  showModal: boolean;
  updateStore: () => void;
  closeModal: any;
  // Dispatch<SetStateAction<boolean>>;
}) {
  const verifiedOptionList = [
    {
      viewValue: "Unverified",
      value: "Unverified",
    },
    {
      viewValue: "Verified",
      value: "Verified",
    },
    {
      viewValue: "Rejected",
      value: "Rejected",
    },
  ];
  const [openForm, setOpenForm] = useState(false);
  console.log(store, "jsonp chunk loading:507 Fetch finished loading: GET ");
  const specificData = null; // Replace with your specific data
  const submitDetail = {
    path: `admin/v1/store/${store._id}`,
    method: "PATCH",
    message: "Form Submitted Successfully",
  };
  return (
    <div>
      {/* {
        openForm &&  */}
      <Form
        columns={4}
        formField={[
          {
            name: "name",
            label: "Shop Name",
            type: FormInputTypeEnum.Text,
            readOnly: true,
          },
          {
            name: "profile",
            label: "Profile",
            type: FormInputTypeEnum.Text,
            readOnly: true,
          },
          {
            name: "typeList",
            label: "typeList",
            type: FormInputTypeEnum.MultiSelect,
            optionList: StoreTypeList,
            // required: true,
            readOnly: true,
          },
          {
            name: "categoryList",
            label: "categoryList",
            type: FormInputTypeEnum.MultiSelect,
            optionList: StoreCategoryList,
            // required: true,
            readOnly: true,
          },
          {
            name: "address",
            label: "Address",
            type: FormInputTypeEnum.Object,
            readOnly: true,
            formField: [
              {
                name: "line1",
                label: "line1",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "line2",
                label: "line2",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "landmark",
                label: "landmark",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "locality",
                label: "locality",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "city",
                label: "city",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "tehsil",
                label: "tehsil",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "district",
                label: "district",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "state",
                label: "state",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "country",
                label: "country",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "postalCode",
                label: "postalCode",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
            ],
          },
          {
            name: "email",
            label: "Email",
            type: FormInputTypeEnum.Email,
            readOnly: true,
          },
          {
            name: "phoneNumberList",
            label: "Phone Number",
            type: FormInputTypeEnum.Array,
            readOnly: true,
            formField: [
              {
                name: "label",
                label: "label",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "countryCode",
                label: "countryCode",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "nationalNumber",
                label: "nationalNumber",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "phoneNumber",
                label: "phoneNumber",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
            ],
          },
          {
            name: "imageList",
            label: "imageList",
            type: FormInputTypeEnum.Array, // todo: add file array
            readOnly: true,
          },
          {
            name: "gst",
            label: "gst",
            type: FormInputTypeEnum.Object,
            formField: [
              {
                name: "number",
                label: "number",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "document",
                label: "document",
                type: FormInputTypeEnum.Text,
                readOnly: true,
              },
              {
                name: "verification",
                label: "verification",
                type: FormInputTypeEnum.Object,
                formField: [
                  {
                    name: "status",
                    label: "status",
                    type: FormInputTypeEnum.Select,
                    optionList: verifiedOptionList,
                  },
                ],
              },
            ],
          },
          {
            name: "verification",
            label: "verification",
            type: FormInputTypeEnum.Object,
            formField: [
              {
                name: "status",
                label: "status",
                type: FormInputTypeEnum.Select,
                optionList: verifiedOptionList,
              },
            ],
          },
          // {
          //   name: "bannerUrl",
          //   label: "Upload Bannere",
          //   placeholder: "Upload Banner",
          //   type: FormInputTypeEnum.Text,
          //   file: {
          //     acceptType: "image/*",
          //     api: {
          //       apiPath: "https://example.com/upload",
          //       method: "POST",
          //     },
          //   },
          //   required: true
          //   // {
          //   //   value: true,
          //   //   errorMessage: "Banner Url is required",
          //   // },
          // },
          // {
          //   name: "banneredUrl",
          //   label: "Upload Banner",
          //   placeholder: "Upload Banner",
          //   type: FormInputTypeEnum.Text,
          //   file: {
          //     acceptType: "image/*",
          //     api: {
          //       apiPath: "https://example.com/upload",
          //       method: "POST",
          //     },
          //   },
          //   required: true
          //   // required: {
          //   //   value: true,
          //   //   errorMessage: "Banner Url is required",
          //   // },
          // },
          // Add more fields as needed
        ]}
        formValues={store}
        // specificData={specificData}
        submitDetail={submitDetail}
        operation="update"
        closeModal={closeModal}
      />
      {/* } */}
    </div>
  );
}
