import { useEffect, useState, useRef } from "react";
import { GetRequest } from "../../../api/Request";
import { FiSearch } from "react-icons/fi";
import "./productList.scss";
import AddButton from "../../../component/addButton/AddButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ProductForm from "./productForm/ProductForm";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

export default function Product(_props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const [showEditProductForm, setShowEditProductForm] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumberAccToSearchedValue, setPageNumberAccToSearchedValue] =
    useState(1);
  const [limit, setLimit] = useState(10);
  const [product, setProduct] = useState<any>([]);
  const productListRef = useRef(null);
  const [hasMoreProduct, setHasMoreProduct] = useState<any>(true);
  const [searchedValueForProduct, setSearchedValueForProduct] =
    useState<any>("");
  const { brandId } = useParams();

  const onIntersectionForProductListRef = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreProduct) {
      if (searchedValueForProduct) {
        setPageNumberAccToSearchedValue(
          (prevPageNumberAccToSearchedValue: any) => {
            return prevPageNumberAccToSearchedValue + 1;
          },
        );
      }
      if (searchedValueForProduct == "") {
        setPageNumber((prevPageNumber: any) => {
          return prevPageNumber + 1;
        });
      }
    }
  };

  useEffect(() => {
    if (productListRef.current) {
      const observer = new IntersectionObserver(
        onIntersectionForProductListRef,
      );
      observer.observe(productListRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [hasMoreProduct, product.length > 0]);

  useEffect(() => {
    if (pageNumberAccToSearchedValue > 1) {
      getProductAccToSearchedValue(searchedValueForProduct);
    }
  }, [pageNumberAccToSearchedValue]);

  useEffect(() => {
    if (pageNumber > 1) {
      getProduct();
    }
  }, [pageNumber]);

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    await GetRequest(
      `v1/product?brand=${
        params.brandId ? params.brandId : ""
      }&limit=${limit}&brand-detail=true&page=${pageNumber}`,
    )
      .then(async (res) => {
        let allProduct = res.data.map((data: any) => {
          return { ...data, isActionMenuOpen: false };
        });
        if (res.data.length < limit) {
          setHasMoreProduct((prevValue: any) => false);
        }
        console.log(allProduct);
        setProduct((prevState: any) => {
          return [...prevState, ...allProduct];
        });
      })
      .catch((err) => {
        console.log(err, "this is err in fetching all product ");
      });
  };

  const getProductAccToSearchedValue = async (value: any) => {
    const debouncedGetProduct = debounce(async (searchValue: any) => {
      let url: string = `v1/product?limit=${limit}&page=${pageNumberAccToSearchedValue}`;

      if (searchValue) {
        url += `&name=${searchValue}`;
      }

      if (brandId) {
        url += `&brand=${brandId}`;
      }

      try {
        const res = await GetRequest(url);

        let allProduct = res.data.map((data: any) => {
          return { ...data, isActionMenuOpen: false };
        });

        if (res.data.length === limit) {
          setHasMoreProduct(true);
        }

        if (res.data.length < limit) {
          setHasMoreProduct(false);
        }

        if (pageNumberAccToSearchedValue === 1) {
          setProduct([...allProduct]);
        }

        if (pageNumberAccToSearchedValue > 1) {
          setProduct((prevState: any) => [...prevState, ...allProduct]);
        }
      } catch (err) {
        console.log(err, "Error fetching all products while searching");
      }
    }, 600);

    debouncedGetProduct(value);
  };

  const handleChangeOnSearchedValue = async (event: any) => {
    let { value } = event.target;
    setPageNumberAccToSearchedValue(1);
    setSearchedValueForProduct((prevState: any) => value);
    await getProductAccToSearchedValue(value);
  };

  return (
    <div className="parent_cnt_order_page">
      <div
        className="order_page_big_cnt"
        style={{
          position: "relative",
        }}
      >
        <div className="search-bar-component">
          <div className="search-bar">
            <input
              type="text"
              name="product-name"
              placeholder="Search Product..."
              onChange={handleChangeOnSearchedValue}
              value={searchedValueForProduct}
            />
            <button className="search-icon">
              <FiSearch />
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        <table className="product-list-custom-table">
          <thead>
            <tr>
              <th>Product Image </th>
              <th>Name</th>
              <th> MRP</th>
              <th> Weight</th>
              <th> Size</th>
              <th> Color</th>
              <th> Flavor</th>
              <th> Piece In 1 Bundle </th>
              <th> Piece In 1 Box</th>
              <th>Action</th>
            </tr>
          </thead>
          {product.length > 0 &&
            product.map((data: any, index: number) => {
              return (
                <tbody key={data._id}>
                  <tr>
                    <td className="product-cnt">
                      <img
                        className="product-pic"
                        src={data.imageList[0]}
                        alt={data.name}
                      />
                    </td>
                    <td>{data.name}</td>
                    <td>{"₹" + data.mrp}</td>
                    {
                      <td>
                        {data.weight
                          ? data.weight?.quantity + " " + data.weight?.unit
                          : "-.-"}
                      </td>
                    }
                    {
                      <td>
                        {data.size
                          ? data.size?.quantity + " " + data.size?.unit
                          : "-.-"}
                      </td>
                    }

                    {<td>{data?.color?.name ? data?.color?.name : "-.-"}</td>}

                    {<td>{data?.flavor?.name ? data?.flavor?.name : "-.-"}</td>}

                    {
                      <td>
                        {data?.info?.pieceInEachBundle
                          ? data?.info?.pieceInEachBundle
                          : "-.-"}
                      </td>
                    }

                    {
                      <td>
                        {data?.info?.pieceInEachBox
                          ? data?.info?.pieceInEachBox
                          : "-.-"}
                      </td>
                    }
                    <td>
                      <span
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          paddingRight: 10,
                        }}
                        onClick={() => {
                          navigate(
                            `/brand/${
                              data.brand?._id ? data.brand?._id : data.brandId
                            }/product/${data._id}`,
                          );
                          // setProductToUpdate(data);
                          // setShowEditProductForm(true);
                        }}
                      >
                        Edit{" "}
                      </span>
                      {data?.productGroupId && (
                        <span
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => {
                            navigate(
                              `/product-group/?id=${data.productGroupId}`,
                            );
                          }}
                        >
                          show Group
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          {product.length > 0 && (
            <div ref={productListRef} style={{ padding: "10px" }}></div>
          )}
        </table>
      </div>
      <div
        className="p-8"
        onClick={() => {
          if (params.brandId) {
            navigate(`/brand/${params.brandId}/create-product`);
          } else
            toast.error("Opps..! plz select brand first", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
        }}
      >
        <AddButton />
      </div>
    </div>
  );
}
