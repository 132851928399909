import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import "./AddButton.scss";

export default function AddButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  // console.log(data, "data in action menu")
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const add = () => {
  //   console.log("Add: ", data.name);
  //   handleClose();
  // };
  // const remove = () => {
  //   console.log("remove: ", data.name);
  //   handleClose();
  // };
  return (
    // <div className='fixed z-10 bottom-4 right-8 md:right-14 sm:right-14 box col-start-5 col-end-12'>
    <div
      className="AddIcon"
      // onClick={() => }
    >
      <IoMdAdd size={20} />
    </div>
  );
}
