import Form from "../../../../component/form/Form";
import { FormInputTypeEnum } from "../../../../enum";
import { CategoryType } from "../category.types";

export default function CategoryForm({
  data,
  showModal,
  updateData,
  closeModal,
}: {
  data: CategoryType | undefined;
  // IStore;
  showModal: boolean;
  updateData: () => void;
  closeModal: any;
  // Dispatch<SetStateAction<boolean>>;
}) {
  let submitDetail = {
    path: `admin/v1/category`,
    method: "POST",
    message: "Form Submitted Successfully",
  };
  if (data?._id) {
    submitDetail = {
      path: `admin/v1/category/${data?._id}`,
      method: "PATCH",
      message: "Form Submitted Successfully",
    };
  }
  return (
    <div>
      {showModal && (
        <Form
          columns={4}
          formField={[
            {
              name: "name",
              label: "Category Name",
              type: FormInputTypeEnum.Text,
              required: true,
            },
            {
              name: "description",
              label: "Description",
              type: FormInputTypeEnum.Text,
              required: true,
            },
            {
              name: "type",
              label: "Type",
              type: FormInputTypeEnum.Select,
              optionList: [
                {
                  value: "Product",
                  viewValue: "Product",
                },
              ],
              initialValue: "Product",
              required: true,
            },
            {
              name: "icon",
              label: "icon",
              type: FormInputTypeEnum.File,
              file: {
                api: { apiPath: `v1/file?type=brand-image`, method: "POST" },
              },
              // required: true,
            },
            {
              name: "subCategoryList",
              label: "Sub Category",
              type: FormInputTypeEnum.Array,
              formField: [
                {
                  name: "name",
                  label: "Sub Category Name",
                  type: FormInputTypeEnum.Text,
                  required: true,
                },
                {
                  name: "description",
                  label: "Sub Category Description",
                  type: FormInputTypeEnum.Text,
                  required: true,
                },
                {
                  name: "icon",
                  label: "Sub Category Icon",
                  type: FormInputTypeEnum.File,
                  file: {
                    api: {
                      apiPath: `v1/file?type=brand-image`,
                      method: "POST",
                    },
                  },
                  // required: true,
                },
              ],
            },
          ]}
          formValues={data}
          // specificData={specificData}
          submitDetail={submitDetail}
          operation={data?._id ? "update" : "create"}
          closeModal={closeModal}
        />
      )}
    </div>
  );
}
