import React from "react";
import "./FormPopupModal.scss";

interface PopupModalProps {
  closeModal: () => void;
  children: React.ReactNode;
}

const FormPopupModal: React.FC<PopupModalProps> = ({
  closeModal,
  children,
}) => {
  const handleClickOutside = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).classList.contains("formPopupModal")) {
      closeModal();
    }
  };

  return (
    <div className="formPopupModal" onClick={handleClickOutside}>
      <div className="formPopupContent">
        <button className="formCloseButton" onClick={closeModal}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default FormPopupModal;
