import React, { ChangeEvent, useEffect, useState } from "react";
import "./FormSelectInput.scss";
import { FormSelectOptionType } from "../../../../type";
import { FormInputTypeEnum } from "../../../../enum";
import { FormField } from "../../Form";

interface FormSelectInputProps {
  formField: FormField;
  // name: string;
  parentKey: string;
  // type: FormInputTypeEnum.Select | FormInputTypeEnum.MultiSelect;
  value: any;
  handleChange: (name: string, value: any) => void;
  // onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  // options: FormSelectOptionType[];
  // required?: boolean;
}

const FormSelectInput: React.FC<FormSelectInputProps> = ({
  formField,
  parentKey,
  value,
  handleChange,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [selectOptions, setSelectOptions] = useState(formField.optionList);
  const [isOpen, setIsOpen] = useState(false);
  const [nativeValue, setNativeValue] = useState<
    FormSelectOptionType | FormSelectOptionType[]
  >();

  console.log(value, "value in sedlect");
  useEffect(() => {
    formField.optionList?.forEach((option: FormSelectOptionType) => {
      if (option.value == value) {
        if (formField.type == FormInputTypeEnum.MultiSelect) {
          let newNativeValue: FormSelectOptionType[] =
            (nativeValue as []) ?? [];
          newNativeValue.push(option);
          setNativeValue(newNativeValue);
        } else {
          setNativeValue(option);
        }
      }
    });
    if (formField.required && (!value || value.length === 0)) {
      setError("This field is required");
    } else {
      setError(null);
    }
  }, [value, formField.required]);

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  // const handleSelectChange = (changeValue: any | any[]) => {
  const handleSelectChange = (event: any) => {
    // todo: mulit-select
    const selectedValue = event.target.value;
    // formField.type === 'multi-select'
    //   ? Array.from(event.target.selectedOptions, option => option.value)
    // : event.target.value;
    // const selectedValue = e.target.value;
    // setNativeValue(changeValue)
    // console.log("Should be focus value without");
    // console.log("Should be focus value", changeValue);
    // let newNativeValue: string | string[] | undefined = formField.type == FormInputTypeEnum.MultiSelect && changeValue?.lenght > 0 ? [] : undefined;
    // changeValue.forEach((selectedOption: FormSelectOptionType) => {
    //   if (formField.type == FormInputTypeEnum.MultiSelect && Array.isArray(newNativeValue)) {
    //     newNativeValue.push(selectedOption.value)
    //   } else {
    //     newNativeValue = selectedOption.value
    //   }
    // });
    handleChange(parentKey, selectedValue);
  };

  return (
    <div className="formSelectInput" onBlur={handleBlur}>
      <label>{formField.name}: </label>
      {/* <Select */}
      <select
        name={formField.name}
        value={value}
        // options={formField.options}
        onChange={handleSelectChange}
        onFocus={handleFocus}
        multiple={formField.type == FormInputTypeEnum.MultiSelect}
        // isMulti={formField.type == FormInputTypeEnum.MultiSelect}
        // className={isOpen ? 'open' : ''}
        // isDisabled={formField.readonly}
        disabled={formField.readOnly}
        required={formField.required}
      >
        {/* <option value="default" selected="selected">Default</option> */}
        <option
          value=""
          // disabled
        >{`Select ${formField.name}`}</option>
        {// isOpen &&
        formField.optionList?.map((option, index) => (
          <option key={index} value={option.value}>
            {option.viewValue}
          </option>
        ))}
      </select>
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default FormSelectInput;
