import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  IBrand,
  IGroup,
  IProduct,
  ProductGroupGroupByEnum,
} from "./group.types";
import { GetRequest } from "../../../api/Request";
import { Link } from "react-router-dom";

type Props = {};
const initialState: Partial<IGroup> = {
  name: "",
  brandId: "",
  productIdList: [],
};

const groupBy = [
  {
    label: ProductGroupGroupByEnum.Same,
    value: ProductGroupGroupByEnum.Same,
  },
  {
    label: ProductGroupGroupByEnum.Different,
    value: ProductGroupGroupByEnum.Different,
  },
  {
    label: ProductGroupGroupByEnum.Mixed,
    value: ProductGroupGroupByEnum.Mixed,
  },
];

async function fetchBrandList(
  params: string,
  setBrandList: Dispatch<SetStateAction<IBrand[]>>,
) {
  let searchParams = "";
  if (params) {
    searchParams = "?name=" + params;
  }
  const response = await GetRequest("v1/brand?" + searchParams);
  setBrandList(response.data);
}

export default function ({}: Props) {
  const [group, setGroup] = useState<Partial<IGroup>>(initialState);
  const [productGroupList, setProductGroupList] = useState<IGroup[]>([]);

  const [brandName, setBrandName] = useState<string>("");

  const [groupName, setGroupName] = useState<string>("");
  const [brandList, setBrandList] = useState<IBrand[]>([]);
  const [updateProductGroup, setUpdateProductGroup] = useState<boolean>(false);
  const [totalProductCount, setTotalProductCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<Record<string, any>>({
    brandId: "",
    name: "",
  });

  useEffect(() => {
    fetchBrandList(brandName, setBrandList);
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams({
      ...(filter.brandId && { brand: filter.brandId }),
      limit: 10,
      page,
    });
    GetRequest("v1/product/product-group?" + searchParams)
      .then((response) => setProductGroupList(response.data))
      .catch((e) => console.log(e));
  }, [groupName, filter]);

  function handleProductSelectInTable(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    if (event.target.checked) {
      setGroup((pre) => ({
        ...pre,
        //@ts-ignore
        productIdList: [...pre.productIdList, event.target.id],
      }));
    } else {
      setGroup((pre) => ({
        ...pre,
        productIdList: pre.productIdList?.filter(
          (item) => item !== event.target.id,
        ),
      }));
    }
  }

  return (
    <div className="m-4">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex flex-column sm:flex-row flex-wrap sm:space-y-0 items-center justify-start ">
          <div className="col-span-2 sm:col-span-1">
            <select
              id="brandId"
              name="brandId"
              onChange={(event) => {
                if (event.target.value.includes("Select")) {
                  let obj = group;
                  //@ts-ignore
                  delete obj[event.target.name];
                  setFilter((pre) => obj);
                } else
                  setFilter((pre) => ({
                    ...pre,
                    brandId: event.target.value,
                  }));
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            >
              <option selected>Select Brand</option>
              {brandList.map((item) => (
                <option value={item._id}>{item.name}</option>
              ))}
            </select>
          </div>
          <div className="col-span-4 sm:col-span-1">
            <label htmlFor="table-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                onChange={(event) =>
                  setFilter((pre) => ({ ...pre, name: event.target.value }))
                }
                placeholder="Search by name"
                className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300  w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          {/* Dropdown menu */}
        </div>

        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                Product name
              </th>
              <th scope="col" className="px-6 py-3">
                Color
              </th>
              <th scope="col" className="px-6 py-3">
                Size
              </th>
              <th scope="col" className="px-6 py-3">
                brand-Id
              </th>
              <th scope="col" className="px-6 py-3">
                Price
              </th>
              <th scope="col" className="px-6 py-3">
                Weight
              </th>
              <th scope="col" className="px-6 py-3">
                Product Count
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {productGroupList.map((product) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="w-4 p-4">
                  <div className="flex items-center">
                    <input
                      id={product._id}
                      type="checkbox"
                      checked={group.productIdList?.includes(product._id)}
                      onChange={handleProductSelectInTable}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="checkbox-table-search-1"
                      className="sr-only"
                    >
                      checkbox
                    </label>
                  </div>
                </td>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {product.name}
                </th>
                <td className="px-6 py-4">{product?.color?.groupBy}</td>
                <td className="px-6 py-4">{product.size?.groupBy}</td>
                <td className="px-6 py-4">
                  {product.brandId}{" "}
                  <span className="font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {" "}
                    {brandList.find((item) => item._id === product.brandId)
                      ?.name || ""}
                  </span>
                </td>
                <td className="px-6 py-4">{product.price.groupBy}</td>
                <td className="px-6 py-4">{product?.weight?.groupBy}</td>
                <td className="px-6 py-4">
                  {product.productIdList.length || "N/A"}
                </td>
                <td className="flex items-center px-6 py-4">
                  <Link
                    to={`/product-group?id=${product._id}`}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Edit
                  </Link>
                  <span className="font-medium text-red-600 dark:text-red-500 hover:underline ms-3">
                    Remove
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav
          className="flex items-end flex-wrap md:flex-row justify-end w-full p-2"
          aria-label="Table navigation"
        >
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 w-full md:inline md:w-auto self-end flex justify-end space-x-3">
            Showing{"  "}
            <span className="font-semibold text-gray-900 dark:text-white">
              1 - 10
            </span>
            {"  "}
            of{"  "}
            <span className="font-semibold text-gray-900 dark:text-white">
              {totalProductCount}
            </span>
          </span>
          <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
            <li onClick={() => (page > 1 ? setPage((pre) => pre - 1) : null)}>
              <span className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                Previous
              </span>
            </li>
            <li>
              <span className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                {page}
              </span>
            </li>

            <li
              onClick={() =>
                productGroupList.length >= 10 * page
                  ? setPage((pre) => pre + 1)
                  : null
              }
            >
              <span className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                Next
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
