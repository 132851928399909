import axios from "axios";
import { toast } from "react-toastify";
export const BASE_URL = "https://api.modulusseventeen.com/api/v1";
// export const BASE_URL = "http://localhost:5500/api/v1";

export default axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const axiosRequest = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

// axiosRequest.interceptors.request.use(async (request) =>request)

axiosRequest.interceptors.response.use(
  async (response) => {
    if (
      response.config &&
      ["post", "put", "patch"].indexOf(response.config?.method as string) > -1
    ) {
      toast.info("Success", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return response.data;
  },
  async (error) => {
    toast.error(error.response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return Promise.reject(error.response.data);
  },
);
