import { StoreTypeEnum } from "../enum";

export const StoreTypeList = [
  { value: StoreTypeEnum.Accessory, viewValue: "Accessories" },
  { value: StoreTypeEnum.Antique, viewValue: "Antiques" },
  { value: StoreTypeEnum.Art, viewValue: "Art" },
  { value: StoreTypeEnum.Bag, viewValue: "Bags" },
  { value: StoreTypeEnum.Bakery, viewValue: "Bakery" },
  { value: StoreTypeEnum.Beverage, viewValue: "Beverage Shop" },
  { value: StoreTypeEnum.Book, viewValue: "Books" },
  { value: StoreTypeEnum.Candy, viewValue: "Candy" },
  { value: StoreTypeEnum.Clothing, viewValue: "Clothing" },
  // { value: StoreTypeEnum.Coffee, viewValue: 'Coffee Shop' },
  { value: StoreTypeEnum.Convenience, viewValue: "Convenience Store" },
  { value: StoreTypeEnum.Cosmetic, viewValue: "Cosmetics" },
  { value: StoreTypeEnum.Dairy, viewValue: "Dairy" },
  { value: StoreTypeEnum.Department, viewValue: "Department Store" },
  { value: StoreTypeEnum.Discount, viewValue: "Discount Store" },
  { value: StoreTypeEnum.Pharmacy, viewValue: "Pharmacy" },
  { value: StoreTypeEnum.ECommerce, viewValue: "E-Commerce" },
  { value: StoreTypeEnum.Electrical, viewValue: "Electrical Goods" },
  { value: StoreTypeEnum.Electronic, viewValue: "Electronics" },
  { value: StoreTypeEnum.Florist, viewValue: "Florist" },
  { value: StoreTypeEnum.Fruit, viewValue: "Fruit Shop" },
  { value: StoreTypeEnum.FrozenFood, viewValue: "Frozen Food" },
  { value: StoreTypeEnum.Furniture, viewValue: "Furniture" },
  { value: StoreTypeEnum.GamingConsole, viewValue: "Gaming Consoles" },
  { value: StoreTypeEnum.Garden, viewValue: "Garden Supplies" },
  { value: StoreTypeEnum.Gift, viewValue: "Gift Shop" },
  { value: StoreTypeEnum.Grocery, viewValue: "Grocery Store" },
  { value: StoreTypeEnum.Herbal, viewValue: "Herbal Products" },
  { value: StoreTypeEnum.HomeAppliance, viewValue: "Home Appliances" },
  { value: StoreTypeEnum.HomeDecor, viewValue: "Home Décor" },
  // { value: StoreTypeEnum.Hotel, viewValue: 'Hotel' },
  { value: StoreTypeEnum.Hypermarket, viewValue: "Hypermarket" },
  { value: StoreTypeEnum.IceCreamParlour, viewValue: "Ice Cream Parlour" },
  { value: StoreTypeEnum.Jewellery, viewValue: "Jewellery" },
  { value: StoreTypeEnum.Kiosk, viewValue: "Kiosk" },
  // { value: StoreTypeEnum.Liquor, viewValue: 'Liquor Store' },
  { value: StoreTypeEnum.MobileAccessories, viewValue: "Mobile Accessories" },
  { value: StoreTypeEnum.MobileService, viewValue: "Mobile Service" },
  { value: StoreTypeEnum.OrganicFood, viewValue: "Organic Food" },
  { value: StoreTypeEnum.Pet, viewValue: "Pet Store" },
  {
    value: StoreTypeEnum.ScentedCandleSoapPerfume,
    viewValue: "Scented Candles, Soaps, Perfumes",
  },
  { value: StoreTypeEnum.Footwear, viewValue: "Footwear" },
  { value: StoreTypeEnum.Souvenir, viewValue: "Souvenirs" },
  { value: StoreTypeEnum.MarketStall, viewValue: "Market Stalls" },
  { value: StoreTypeEnum.Snack, viewValue: "Snack Shop" },
  { value: StoreTypeEnum.Stationery, viewValue: "Stationery" },
  { value: StoreTypeEnum.Supermarket, viewValue: "Supermarket" },
  { value: StoreTypeEnum.MeatShop, viewValue: "Meat Shop" },
  { value: StoreTypeEnum.Tobacco, viewValue: "Tobacco Shop" },
  { value: StoreTypeEnum.ToolAndEquipment, viewValue: "Tools and Equipment" },
  { value: StoreTypeEnum.Toy, viewValue: "Toy Store" },
  { value: StoreTypeEnum.Vegetable, viewValue: "Vegetable Shop" },
  { value: StoreTypeEnum.Warehouse, viewValue: "Warehouse" },
  { value: StoreTypeEnum.General, viewValue: "General Store" },
  { value: StoreTypeEnum.Restaurant, viewValue: "Restaurant" },
  { value: StoreTypeEnum.HairSalon, viewValue: "Hair Salon" },
];
