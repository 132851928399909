export enum StoreTypeEnum {
  Accessory = "Accessory",
  Antique = "Antique",
  Art = "Art",
  Bag = "Bag",
  Bakery = "Bakery",
  Beverage = "Beverage",
  Book = "Book",
  Candy = "Candy",
  Clothing = "Clothing",
  Convenience = "Convenience",
  Cosmetic = "Cosmetic",
  Dairy = "Dairy",
  Department = "Department",
  Discount = "Discount",
  Pharmacy = "Pharmacy",
  ECommerce = "ECommerce",
  Electrical = "Electrical",
  Electronic = "Electronic",
  Florist = "Florist",
  Fruit = "Fruit",
  FrozenFood = "FrozenFood",
  Furniture = "Furniture",
  GamingConsole = "GamingConsole",
  Garden = "Garden",
  Gift = "Gift",
  Grocery = "Grocery",
  Herbal = "Herbal",
  HomeAppliance = "HomeAppliance",
  HomeDecor = "HomeDecor",
  // Hotel = 'Hotel',
  Hypermarket = "Hypermarket",
  IceCreamParlour = "IceCreamParlour",
  Jewellery = "Jewellery",
  Kiosk = "Kiosk",
  // Liquor = 'Liquor',
  MobileAccessories = "MobileAccessories",
  MobileService = "MobileService",
  OrganicFood = "OrganicFood",
  Pet = "Pet",
  ScentedCandleSoapPerfume = "ScentedCandleSoapPerfume",
  Footwear = "Footwear",
  Souvenir = "Souvenir",
  MarketStall = "MarketStall",
  Snack = "Snack",
  Stationery = "Stationery",
  Supermarket = "Supermarket",
  MeatShop = "MeatShop",
  Tobacco = "Tobacco",
  ToolAndEquipment = "ToolAndEquipment",
  Toy = "Toy",
  Vegetable = "Vegetable",
  Warehouse = "Warehouse",
  General = "General",
  Restaurant = "Restaurant",
  HairSalon = "HairSalon",
  Other = "Other",
}
