// import { PhoneNumberList } from "../order/order.types";
interface PhoneNumberList {
  label: string;
  phoneNumber: string;
}

export enum ProductGroupGroupByEnum {
  Same = "Same",
  Different = "Different",
  Mixed = "Mixed",
}
export type ProductGroupProductGroupGroupByObject = ProductGroupGroupByEnum;
export interface IGroup {
  _id: string;
  name: string;
  weight?: { groupBy: ProductGroupProductGroupGroupByObject };
  flavor?: { groupBy: ProductGroupProductGroupGroupByObject };
  color?: { groupBy: ProductGroupProductGroupGroupByObject };
  price: { groupBy: ProductGroupProductGroupGroupByObject };
  size?: { groupBy: ProductGroupProductGroupGroupByObject };
  brandId: string;
  productIdList: string[];
}

export interface IBrand {
  _id: string;
  name: string;
  description?: string;
  phoneNumberList?: PhoneNumberList[];
  email?: string;
  websiteUrl?: string;
  logo?: string;
}

export interface IProduct {
  _id: string;
  status: string;
  slug: string;
  name: string;
  description: string;
  brandID: string;
  mrp: number;
  hsnCode: number;
  info: Info;
  createdAt: Date;
  updatedAt: Date;
  size?: Size;
  weight?: Weight;
  imageList: string[];
  taxPercentageList: TaxPercentageList[];
  category?: string;
  subCategory?: string;
  categoryID: string;
  subCategoryID: string;
  color?: Color;
  flavor: Flavor;
}
export type Info = {
  pieceInEachBundle?: number;
  pieceInEachBox: number;
};

export type Weight = {
  quantity: number;
  unit: string;
};

export type Flavor = {
  name: string;
};

export type Color = {
  name: string;
};

export type Size = {
  quantity: number;
  unit: string;
};

export type TaxPercentageList = {
  type: Type;
  condition: Condition;
  percentage: number;
};

export type Condition = {
  basedOn: BasedOn;
  is: Is;
};

export type BasedOn = "State";

export type Is = "Same" | "Different";

export type Type = "CGST" | "SGST" | "IGST";
