import React from "react";
import FormInput from "../FormInput";
import "./FormArrayInput.scss";
import { FormField, RenderFormFields } from "../../Form";

interface FormArrayInputProps {
  formField: FormField;
  // label?: string;
  // name: string;
  parentKey: string;
  values: any[];
  setLocalFormValues: (value: any) => void;
  handleChange: (name: string, value: any) => void;
  // type: FormInputTypeEnum;
  // formField?: FormField[];
  // file?: { api: { apiPath: string; method: string }, acceptType: string };
  // errorMessage?: string;
  // required?: boolean;
  // placeholder?: string;
  columns: number;
  handleFieldChange?: (name: string, value: any) => void;
  error: any;
}

const FormArrayInput: React.FC<FormArrayInputProps> = ({
  formField,
  // label,
  // name,
  parentKey,
  values,
  setLocalFormValues,
  handleChange,
  // type,
  // formField,
  // file,
  // errorMessage,
  // required,
  // placeholder,
  columns,
  handleFieldChange,
  error,
}) => {
  const addItem = () => {
    let object = {};
    formField.formField?.forEach((field: FormField) => {
      object = {
        ...object,
        [field.name]: "",
      };
    });
    // onChange([...values, type === 'object' ? {} : '']);
    handleChange(parentKey, [...values, object]);
  };

  const removeItem = (index: number) => {
    handleChange(
      parentKey,
      values.filter((_, i) => i !== index),
    );
  };

  const handleItemChange = (index: number, value: any) => {
    const updatedValues = values.map((item, i) => (i === index ? value : item));
    handleChange(parentKey, updatedValues);
  };

  // const handleObjectItemChange = (
  //   index: number,
  //   field: string,
  //   event: ChangeEvent<HTMLInputElement>
  // ) => {
  //   const updatedValues = values.map((item, i) =>
  //     i === index ? { ...item, [field]: event.target.value } : item
  //   );
  //   handleChange(updatedValues);
  // };

  return (
    <div className="formArrayInput">
      <label>{formField.label}: </label>
      <div className="formArrayInputItemList">
        {values.map((value, index) => (
          <div key={index} className="formArrayInputItem">
            {Array.isArray(formField.formField) ? (
              RenderFormFields(
                {
                  formField: formField.formField,
                  localFormValues: value,
                  setLocalFormValues: setLocalFormValues,
                  parentKey: parentKey + "." + index,
                  // handleChange: props.handleChange,
                  // handleChange: handleChange(field.name, props.localFormValues[field.name]),
                  columns: columns,
                  error: error,
                  handleFieldChange: handleFieldChange,
                },

                // label,
                // name,
                // values,
                // onChange,
                // type,
                // formField.formField,
                // file,
                // errorMessage,
                // required,
                // placeholder,
                // renderFormFields
              )
            ) : (
              // <Form
              //   formField={formField}
              //   submitDetail={{ path: '', method: '', message: '' }}
              //   operation='create'
              //   closeModal={() => { }}
              //   columns={1}
              //   updateKeysOnly={false}
              //   formValues={value}
              //   handleFieldChange={(fieldName, fieldValue) => handleObjectItemChange(index, fieldName, fieldValue)}
              // />
              //todo: change to renderFormFields
              <FormInput
                formField={{
                  label: `Item ${index + 1}`,
                  type: formField.type,
                  name: `${formField.name}[${index}]`,
                  errorMessage: formField.errorMessage,
                  file: formField.file,
                  required: formField.required,
                  placeholder: formField.placeholder,
                  readOnly: formField.readOnly,
                }}
                // label={`Item ${index + 1}`}
                // type={type}
                // name={`${name}[${index}]`}
                parentKey={parentKey + "." + index}
                value={value}
                handleChange={handleChange}
                // errorMessage={errorMessage}
                // file={file}
                // required={required}
                // placeholder={placeholder}
              />
              // <input
              //   type="text"
              //   value={value}
              //   onChange={(e) => handleItemChange(index, e)}
              // />
            )}
            <button
              type="button"
              className="formArrayButton"
              onClick={() => removeItem(index)}
              disabled={formField.readOnly}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
      {/* todo: not to show buttons if readOnly */}
      <button
        type="button"
        className="formArrayButton"
        onClick={addItem}
        disabled={formField.readOnly}
      >
        Add
      </button>
    </div>
  );
};

export default FormArrayInput;
