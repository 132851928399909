import { StoreCategoryEnum } from "../enum";

export const StoreCategoryList = [
  { value: StoreCategoryEnum.Distributor, viewValue: "Distributor" },
  { value: StoreCategoryEnum.Hospitality, viewValue: "Hospitality" },
  { value: StoreCategoryEnum.Manufacturer, viewValue: "Manufacturers" },
  { value: StoreCategoryEnum.PersonalCare, viewValue: "Personal Care" },
  { value: StoreCategoryEnum.Retailer, viewValue: " Retailer" },
  { value: StoreCategoryEnum.ServiceProvider, viewValue: "Service Provider" },
  { value: StoreCategoryEnum.Wholesaler, viewValue: "Wholesaler" },
];
