import React, { ChangeEvent, useEffect, useState } from "react";
import { PostRequest } from "../../../api/Request";
import "./FormInput.scss";
import { FormField } from "../Form";

interface FormInputProps {
  formField: FormField;
  // label: string | undefined;
  // type: string;
  // name: string;
  parentKey: string;
  value: any;
  handleChange: (name: string, value: any) => void;
  // errorMessage?: string;
  // file?: { api: { apiPath: string; method: string } };
  // required?: boolean;
  // placeholder?: string;
  // readOnly?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  formField,
  // label,
  // type,
  // name,
  parentKey,
  value,
  handleChange,
  // errorMessage,
  // file,
  // required,
  // placeholder,
  // readOnly
}) => {
  const [error, setError] = useState<string | null>(null);

  // const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
  //   const fileData = event.target.files?.[0];
  //   if (fileData && formField.file?.api.apiPath) {
  //     const formData = new FormData();
  //     formData.append('file', fileData);
  //     try {
  //       const response: any = await PostRequest(formField.file.api.apiPath, formData);
  //       handleChange(parentKey, response.data.url);
  //     } catch (error) {
  //       setError('File upload failed');
  //     }
  //   }
  // };

  useEffect(() => {
    if (formField.required && !value) {
      setError("This field is required");
    } else {
      setError(null);
    }
  }, [value, formField.required]);

  return (
    <div className="formInput">
      <label>{formField.label}: </label>
      {/* {formField.type === 'file' ? (
        <input
          type="file"
          name={formField.name}
          onChange={handleFileChange}
          required={formField.required}
          readOnly={formField.readOnly}
        />
      ) : ( */}
      <input
        type={formField.type}
        name={formField.name}
        value={value}
        onChange={(e) => handleChange(parentKey, e.target.value)}
        required={formField.required}
        placeholder={formField.placeholder}
        readOnly={formField.readOnly}
      />
      {/* )} */}
      {error && <span className="error">{error}</span>}
      {formField.errorMessage && !value && (
        <span className="error">{formField.errorMessage}</span>
      )}
    </div>
  );
};

export default FormInput;
