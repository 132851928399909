import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  IBrand,
  IGroup,
  IProduct,
  ProductGroupGroupByEnum,
} from "./group.types";
import { GetRequest } from "../../../api/Request";
import { PatchRequest, PostRequest } from "../../../api/Request";
import { AxiosServerResponse, SubCategory } from "../category/category.types";
import { useSearchParams } from "react-router-dom";

type Props = {};
export const initialState: Partial<IGroup> = {
  name: "",
  brandId: "",
  productIdList: [],
};

export const groupBy = [
  {
    label: ProductGroupGroupByEnum.Same,
    value: ProductGroupGroupByEnum.Same,
  },
  {
    label: ProductGroupGroupByEnum.Different,
    value: ProductGroupGroupByEnum.Different,
  },
  {
    label: ProductGroupGroupByEnum.Mixed,
    value: ProductGroupGroupByEnum.Mixed,
  },
];
async function fetchProductGroupList(
  params: string,
  setProductGroupList: Dispatch<SetStateAction<IGroup[]>>,
) {
  // let searchParams = "";
  // if (params) {
  //   searchParams = "?name=" + params;
  // }
  const response = await GetRequest("v1/product/product-group?limit=50");
  setProductGroupList(response.data);
}

async function fetchBrandList(
  params: string,
  setBrandList: Dispatch<SetStateAction<IBrand[]>>,
) {
  let searchParams = "";
  if (params) {
    searchParams = "?name=" + params;
  }
  const response = await GetRequest("v1/brand?" + searchParams);
  setBrandList(response.data);
}

async function fetchCategoryList(
  setCategoryList: Dispatch<SetStateAction<AxiosServerResponse>>,
) {
  try {
    const response = await GetRequest(
      "v1/category?page=1&limit=50&type=Product",
    );
    //@ts-ignore
    setCategoryList(response);
  } catch (error) {}
}

export default function ProductGrouping({}: Props) {
  const [group, setGroup] = useState<Partial<IGroup>>(initialState);
  const [productGroupList, setProductGroupList] = useState<IGroup[]>([]);
  const [productList, setProductList] = useState<IProduct[]>([]);
  const [brandName, setBrandName] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");
  const [brandList, setBrandList] = useState<IBrand[]>([]);
  const [updateProductGroup, setUpdateProductGroup] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<Record<string, any>>({
    category: "",
    subCategory: "",
  });
  const [categoryList, setCategoryList] = useState<AxiosServerResponse>({
    data: [],
    statusCode: 0,
    success: false,
    limit: 50,
    page: 1,
  });
  const [params] = useSearchParams();

  const [subCategoryList, setSubCategoryList] = useState<SubCategory[]>([]);
  useEffect(() => {
    fetchProductGroupList(groupName, setProductGroupList);
    fetchCategoryList(setCategoryList);
  }, []);

  useEffect(() => {
    const urlSearchParam = new URLSearchParams({
      ...(productName && { name: productName }),
      ...(filter.category && { category: filter.category }),
      ...(filter.subCategory && { category: filter.subCategory }),
      ...(group.brandId && { brand: group.brandId }),
      "ungrouped-product-only": true,
      page,
      limit: "10",
    });
    GetRequest("v1/product?" + urlSearchParam)
      .then((res) => {
        setProductList(res.data);
        //@ts-ignore
        setPage(res?.page);
      })
      .catch((e) => console.log(e));
  }, [productName, group.brandId, page, filter]);
  useEffect(() => {
    if (params.get("id")) {
      let group = productGroupList.find((g) => g._id === params.get("id"));
      if (!group) {
        GetRequest("v1/product/product-group/" + params.get("id"))
          .then((response) => {
            setGroup(response.data);
            setUpdateProductGroup(true);
          })
          .catch((e) => console.log(e));
      }
    }
  }, [params]);

  useEffect(() => {
    fetchBrandList(brandName, setBrandList);
  }, []);
  function handleDropDownChange(
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) {
    if (["name", "brandId"].includes(event.target.name)) {
      setGroup((pre) => ({
        ...pre,
        [event.target.name]: event.target.value,
      }));
    } else if (event.target.value.includes("Select")) {
      let obj = group;
      //@ts-ignore
      delete obj[event.target.name];
      setGroup((pre) => obj);
    } else
      setGroup((pre) => ({
        ...pre,
        [event.target.name]: { groupBy: event.target.value },
      }));
  }

  function handleProductSelectInTable(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    if (event.target.checked) {
      setGroup((pre) => ({
        ...pre,
        //@ts-ignore
        productIdList: [...pre.productIdList, event.target.id],
      }));
    } else {
      setGroup((pre) => ({
        ...pre,
        productIdList: pre.productIdList?.filter(
          (item) => item !== event.target.id,
        ),
      }));
    }
  }
  function handleResetForm() {
    setGroup(initialState);
    setUpdateProductGroup(false);
  }
  async function handleAddGroup() {
    try {
      const response = await PostRequest("v1/product/product-group", group);
    } catch (error) {}
  }
  async function handleUpdateGroup() {
    try {
      const response = await PatchRequest(
        "v1/product/product-group/" + group._id,
        {
          name: group.name,
          ...(group.weight && { weight: group.weight }),
          ...(group.flavor && { flavor: group.flavor }),
          ...(group.color && { color: group.color }),
          ...(group.size && { size: group.size }),
          brandId: group.brandId,
          price: group.price,
          productIdList: group.productIdList,
        },
      );
    } catch (error) {}
  }
  async function filterGroupByName(event: React.ChangeEvent<HTMLInputElement>) {
    setGroupName(event.target.value);
  }

  return (
    <>
      <div className="flex flex-row flex-1 justify-between px-4 py-2 w-full h-screen">
        <div className="basis-3/4 p-3">
          <div className="flex items-center justify-between px-4 md:p-5 border-b rounded-t dark:border-gray-600 w-full">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Create New Product-Group
            </h3>
          </div>
          <form className="p-4 md:p-5 w-full">
            <div className="grid gap-4 mb-4 grid-cols-6 w-full">
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Group Name *
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={group.name}
                  onChange={handleDropDownChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type product-group name"
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="Weight"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Weight
                </label>
                <select
                  id="weight"
                  name="weight"
                  onChange={handleDropDownChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  <option selected>Select weight</option>
                  {groupBy.map((item) => (
                    <option
                      value={item.value}
                      selected={item.value === group.weight?.groupBy}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="flavor"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Flavor
                </label>
                <select
                  id="flavor"
                  name="flavor"
                  onChange={handleDropDownChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  <option selected>Select flavor</option>
                  {groupBy.map((item) => (
                    <option
                      value={item.value}
                      selected={item.value === group.flavor?.groupBy}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="Color"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Color
                </label>
                <select
                  id="Color"
                  name="color"
                  onChange={handleDropDownChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  <option selected>Select Color</option>
                  {groupBy.map((item) => (
                    <option
                      value={item.value}
                      selected={item.value === group.color?.groupBy}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="Price"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Price *
                </label>
                <select
                  id="Price"
                  name="price"
                  onChange={handleDropDownChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  <option selected>Select Price</option>
                  {groupBy.map((item) => (
                    <option
                      value={item.value}
                      selected={item.value === group.price?.groupBy}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="Size"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Size
                </label>
                <select
                  id="Size"
                  name="size"
                  onChange={handleDropDownChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  <option selected>Select Size</option>
                  {groupBy.map((item) => (
                    <option
                      value={item.value}
                      selected={item.value === group.size?.groupBy}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  htmlFor="Brand"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Brand *
                </label>
                <select
                  id="Brand"
                  name="brandId"
                  onChange={handleDropDownChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  <option selected>Select Brand</option>
                  {brandList.map((item) => (
                    <option
                      value={item._id}
                      selected={item._id === group.brandId}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Product Table Start */}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <div className="flex flex-column sm:flex-row flex-wrap sm:space-y-0 items-center justify-between ">
                <div className="grid  mb-4 grid-cols-8 w-full">
                  <div className="col-span-2 sm:col-span-1">
                    <select
                      id="category"
                      name="category"
                      onChange={(event) => {
                        if (event.target.value.includes("Select")) {
                          let obj = group;
                          //@ts-ignore
                          delete obj[event.target.name];
                          setFilter((pre) => obj);
                        } else {
                          setFilter((pre) => ({
                            ...pre,
                            category: event.target.value,
                          }));
                          setSubCategoryList(
                            categoryList?.data.filter(
                              (category) => category._id === event.target.value,
                            )[0]?.subCategoryList,
                          );
                        }
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    >
                      <option selected>Select category</option>
                      {categoryList.data.map((item) => (
                        <option value={item._id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <select
                      id="subCategory"
                      name="subCategory"
                      onChange={(event) => {
                        if (event.target.value.includes("Select")) {
                          let obj = group;
                          //@ts-ignore
                          delete obj[event.target.name];
                          setFilter((pre) => obj);
                        } else
                          setFilter((pre) => ({
                            ...pre,
                            subCategory: event.target.value,
                          }));
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    >
                      <option selected>Select Sub-Category</option>
                      {subCategoryList.map((item) => (
                        <option value={item._id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-4 sm:col-span-1">
                    <label htmlFor="table-search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="table-search"
                        onChange={(event) => setProductName(event.target.value)}
                        className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300  w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for items"
                      />
                    </div>
                  </div>
                  {/* Dropdown menu */}
                </div>
              </div>

              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="p-4">
                      <div className="flex items-center">
                        <label
                          htmlFor="checkbox-all-search"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Product name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Color
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Category
                    </th>
                    {/* <th scope="col" className="px-6 py-3">
                      Accessories
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Available
                    </th> */}
                    <th scope="col" className="px-6 py-3">
                      Price
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Weight
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Flavor
                    </th>
                    {/* <th scope="col" className="px-6 py-3">
                      Action
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {productList.map((product) => (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="w-4 p-4">
                        <div className="flex items-center">
                          <input
                            id={product._id}
                            type="checkbox"
                            checked={group.productIdList?.includes(product._id)}
                            onChange={handleProductSelectInTable}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="checkbox-table-search-1"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {product.name}
                      </th>
                      <td className="px-6 py-4">{product?.color?.name}</td>
                      <td className="px-6 py-4">{product.category}</td>
                      {/* <td className="px-6 py-4">Yes</td>
                    <td className="px-6 py-4">Yes</td> */}
                      <td className="px-6 py-4">{product.mrp}</td>
                      <td className="px-6 py-4">
                        {product?.weight?.quantity + " " + product.weight?.unit}
                      </td>
                      <td className="px-6 py-4">{product?.flavor?.name}</td>
                      {/* <td className="flex items-center px-6 py-4">
                      <a
                        href="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit
                      </a>
                      <a
                        href="#"
                        className="font-medium text-red-600 dark:text-red-500 hover:underline ms-3"
                      >
                        Remove
                      </a>
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav
                className="flex items-end flex-wrap md:flex-row justify-end w-full p-2"
                aria-label="Table navigation"
              >
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                  <li
                    onClick={() =>
                      page > 1 ? setPage((pre) => pre - 1) : null
                    }
                  >
                    <span className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                      Previous
                    </span>
                  </li>
                  <li>
                    <span className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                      {page}
                    </span>
                  </li>

                  <li
                    onClick={() =>
                      productList.length >= 10 * page
                        ? setPage((pre) => pre + 1)
                        : null
                    }
                  >
                    <span className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                      Next
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="flex w-full flex-row justify-between items-center">
              {updateProductGroup ? (
                <button
                  type="button"
                  onClick={handleUpdateGroup}
                  className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Update Product-Group
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleAddGroup}
                  className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <svg
                    className="me-1 -ms-1 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Add new product-group
                </button>
              )}
              <button
                type="button"
                onClick={handleResetForm}
                className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Reset Form
              </button>
            </div>
          </form>
        </div>
        <div className="basis-1/4 border-l-slate-300 border-l-2 p-3">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="pb-4 bg-white dark:bg-gray-900">
              <label htmlFor="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  value={groupName}
                  onChange={filterGroupByName}
                  className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for Product-Groups"
                />
              </div>
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product-Group name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {productGroupList.map((productGroup, index) => (
                  <tr
                    key={productGroup.name + index}
                    className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${
                      !productGroup.name
                        .toLocaleLowerCase()
                        .includes(groupName.toLocaleLowerCase())
                        ? "hidden"
                        : ""
                    }`}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {productGroup.name}
                    </th>
                    <td className="px-6 py-4">
                      <span
                        onClick={() => {
                          setUpdateProductGroup(true);
                          setGroup(productGroup);
                        }}
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
